import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GallerySwiper from './swiper';




function Album(props){
  
  const [containersize, setContainersize] = useState(0)
  const [pos,setPos] = useState(0)
  const [selecteditem, setSelecteditem] = useState(0)
  const [bdcount, setBdcount] = useState(0)
  const rndid = Math.floor(Math.random() * 1000)
  const [pitems, setPitems] = useState({})


    const styles ={

      width:"100%",
      borderRadius:"20px",
      float:"left",
      height: "100%"
    }

  /*  useEffect(()=>{

      setPitems(props.photos.photoGallery)

    },[props.photos.photoGallery])*/
    //console.log(props.photos.photoGallery)
    const lst = [

      {"img":"https://www.w3schools.com/w3images/fjords.jpg"},

      {"img":"https://thumbs.dreamstime.com/b/business-accounting-team-meeting-room-office-using-pen-pointing-paperwork-finance-concept-business-accounting-team-meeting-154771088.jpg"}
    ]  
    
    //console.log(lst.length)

    const prevHandler=()=>{
     
      if(0 >= pos && pos !== -500){ 
      setPos(prevPos => prevPos - 500)
      
      var preitem = document.querySelector("."+props.cls + "_" + rndid + (selecteditem))
      preitem.style.backgroundColor = "#cccccc"
      var currentitem = document.querySelector("."+props.cls + "_" + rndid + (selecteditem+1))
     currentitem.style.backgroundColor = "#ff0000"
     setSelecteditem(prevSelecteditem => prevSelecteditem - 1 )
      }
       
    }

    const nextHandler=()=>{
    
      if(pos < 0){ 
      setPos(prevPos => prevPos + 500)
    
      var preitem = document.querySelector("."+props.cls + "_" + rndid + (selecteditem+1))
      preitem.style.backgroundColor = "#ff0000"
      var currentitem = document.querySelector("."+props.cls + "_" + rndid + (selecteditem+2))
     currentitem.style.backgroundColor = "#cccccc"
      setSelecteditem(prevSelecteditem => prevSelecteditem + 1 )
      
    }

    }



    /*onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => this.handleTouchEnd()}
        // The following event handlers are for mouse compatibility:
        onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
        onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
        onMouseUp={() => this.handleMouseUp()}
        onMouseLeave={() => this.handleMouseLeave()}*/

let phitems = props.photos
    return(
          <div className="albumitem">
          <div className="titleLabel" style={{padding:"15px 0px"}}><Link to="#">{props.title}</Link> </div>
        <GallerySwiper photoitems={phitems}  />
        <div className="interactiveicons">
            <Link to="#"><i className="far fa-comment"></i><span style={{fontSize: "10px"}}>8</span></Link> 
            <Link to="#" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i><span style={{fontSize: "10px"}}>245</span></Link> 
            <Link to="#" className="twitter"><i className="fa fa-twitter" aria-hidden="true"></i><span style={{fontSize: "10px"}}>105</span></Link>
            <Link to="#" className="whatsapp"><i className="fa fa-whatsapp" aria-hidden="true"></i><span style={{fontSize: "10px"}}>10</span></Link>
            <Link to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i><span style={{fontSize: "10px"}}>15</span></Link>
 
            </div>
        </div>
    )
}

export default Album;