import { useQuery } from '@apollo/client';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { TRENDS } from '../graphql/queries.js'
import DOMPurify from 'dompurify';
import Icon from '@mdi/react';
import {mdiTwitter } from '@mdi/js';
import {mdiWhatsapp } from '@mdi/js';
import {mdiEmailOutline } from '@mdi/js';

function Trending(){

    //const {data} = useQuery(TRENDS)
    const baseurl = window.location.origin;
    useEffect(()=>{
        fetchnews();
      },[]);
  
    //  if(data){
         // console.log(data)
     // }
    const [items, setItems] = useState([]);

    const fetchnews = async () =>{
      
       // const data = await fetch('https://www.myjoyonline.com/wp-json/wp/v2/tags?taxonomy=post_tag&order_by=count&order=desc&context=view&hide_empty=true&meta=view');
        //const data = await fetch('https://www.myjoyonline.com/wp-json/wordpress-popular-posts/v1/popular-posts?freshness=1&range=last24hours');
        const data = await fetch('https://www.myjoyonline.com/wp-json/mjo/v1/trendingstories?freshness=1&range=last24hours');
        const items = await data.json();   
        
         setItems(items);
        // console.log(items)
      
     }

    return(
     
    <div className="popular-trending">
        <div className="sitelabels" style={{paddingLeft:"15px", margin:"15px auto",fontWeight:"500"}}>Popular Topics</div>
        {items.map((item, index) => (

            <div style={{display:"block", padding: "14px 10px"}} >
                

                <div style={{position:'absolute', right: '5px'}} className="popular-more-options">
                    
                    <span style={{borderRadius:'200%', backgroundColor: '#888888', width: '3px', height: '3px', marginRight: '2px', display:'inline-block'}}></span>
                    <span style={{borderRadius:'200%', backgroundColor: '#888888', width: '3px', height: '3px', marginRight: '2px', display:'inline-block'}}></span>
                    <span style={{borderRadius:'200%', backgroundColor: '#888888', width: '3px', height: '3px', display:'inline-block'}}></span>
                    <div className="menu">


                    <div className="interactiveicons">
         
            <Link to="#" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i></Link> 
            <Link to="#" className="twitter"><Icon path={mdiTwitter} title="User Profile" size={1} horizontal vertical rotate={180} color="#1da1f2" /></Link>
            <Link to="#" className="whatsapp"><Icon path={mdiWhatsapp} title="User Profile" size={1} horizontal vertical rotate={180} color="#25d366" /></Link>
            <Link to="#"><Icon path={mdiEmailOutline} title="User Profile" size={1} horizontal vertical rotate={180} color="#484848" /></Link> 
          
            </div>
                    </div>
                    </div>                
                

                   <Link to={{pathname:"/tag/"+(item.tag.replace(/ /g,"-")).replace(/\//g,"-"), state:{tag:item.tag}}}>{item.tag}</Link> 
                   <Link to={{pathname:"/"+item.slug}} style={{display: "block", color:"#666666", padding:"2px 0px", fontSize:"13px"}}> <h1 style={{fontSize: "18px", lineHeight:".9", fontWeight:"400"}}>{item.title}</h1>
           </Link> 
                  {/*<Link className="trendingstory" style={{display: "block", fontSize:"12px", padding:"2px 0px"}}  key={index+item.slug} to={{pathname:item.slug,state:{itemid:item.id,title:item.title,slug:item.slug}}}><span style={{color:"#666666", fontWeight:"400", lineHeight:"1"}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title)}}></span>
                   </Link>*/}
            
            </div>
        ))}
        </div>
    
    );
    }
    
export default Trending;