import FeedItem from './feeditem.js';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { ApolloClient, InMemoryCache, useQuery, gql, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Topstory from './topstory.js';
import { SEARCH_DATA } from '../graphql/queries.js'
import DOMPurify from 'dompurify';

function Search( props ){
  const [searchitems, setSearchitems] = useState()
  let baseurl = window.location.origin

  const {error, loading, data} = useQuery( SEARCH_DATA, {variables:{search: props.search }} );

  useEffect(()=>{
    if(data){ 
        setSearchitems(data.posts.nodes)
        
    }

  },[data])

  const [items, setItems] = useState([]);

if(loading){

    return(

        <div className={props.hideshow? "showelement":"hideelement"} style={{position: "relative",zIndex:"0",top:"20px", marginTop:"10px", width:"250%", left:"193px", position:"absolute",backgroundColor:"#ffffff", borderRadius:"20px", border:"1px solid #eee", padding:"25px 15px"}}>
            <h1>Loading...</h1>
        </div>
    )
}
    return(
        <div className={props.hideshow? "showelement":"hideelement"} style={{zIndex:"0",top:"20px", marginTop:"10px", width:"250%", left:"193px", position:"absolute", backgroundColor:"#ffffff", borderRadius:"20px", border:"1px solid #eee", padding:"25px 15px", height: "90vh", overflowY: "scroll"}}>
        <div style={{borderBottom:"1px solid #cccc", padding:"0px 0px 10px 0px", marginBottom:"15px"}}>You Searched: <span style={{fontWeight:"500", fontStyle:"italic"}}>{ props.search }</span></div>
        {data.posts.nodes.length > 0 ? 

         data.posts.nodes.map((item,index) => {

       return ( <div>
       

           <a href={"/"+item.slug}> <h1 style={{fontSize: "18px", lineHeight:".9", fontWeight:"400"}}>{item.title}</h1>
           </a> 
           <div style={{fontSize:"12px"}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.excerpt)}}></div>
        <p>
        
        </p>
        </div>
       )
       
           })
        :

<div className={props.hideshow? "showelement":"hideelement"} style={{position: "relative",zIndex:"0",top:"20px", marginTop:"10px", width:"250%", left:"193px", position:"absolute",backgroundColor:"#ffffff", borderRadius:"20px", border:"1px solid #eee", padding:"25px 15px", overflow:"scroll"}}>
    There was no results found for "{props.search}"</div>
}
</div>
    );
}

export default Search;