import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import SideBar from './sidebar.js';
import StoryItem from './storyitems.js';
import { ApolloClient, InMemoryCache, useQuery, gql, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {LOAD_STORY } from '../graphql/queries.js';
import RelatedStories from './relatedstories.js';
import GoogleAds from './googleads.js';
import MyLoader from './myjoyloader.js';
import { mdiCommentMinusOutline } from '@mdi/js';
import { useCookies } from 'react-cookie';
import Customad from './customad.js';


function Single( {match} ){

  const location = useLocation();
  const [cookies, setCookie] = useCookies(['user']);
  const slug = match.params.id;
  const [newsitem, setNewsitem] = useState([]);
  const [storyimage, setStoryimage] = useState(location.state?.storyimage)
  const [tags,setTags] = useState(location.state?.tags);
  const [title,setTitle] = useState(location.state?.title)
  const [storycontent,setStorycontent] = useState("")
  const [id,setId] = useState(location.state?.itemid);
     
  
  const {error, loading, data} = useQuery(LOAD_STORY, {variables: {slug: slug}});
  
   


      useEffect(()=> {
        if(data){
           
            setNewsitem(data.postBy)
            setStoryimage(data.postBy.featuredImage.node.sourceUrl)
            let n = data.postBy.tags.nodes.length
            let tgs = ""
            let storyC = data.postBy.content

            for(let i=0;i<n;i++){
                if(i < data.postBy.tags.nodes.length-1){

                    tgs += data.postBy.tags.nodes[i].name.replace(/""/gi,"-") +",";
                }else{
                    tgs += data.postBy.tags.nodes[i].name.replace(/""/gi,"-")
                }
                 
            }

            setTags(tgs);
        }          

      },[data])
  
      
      if(error){ 
          
        return <div>Error Loading Content</div>
    
    }


    if(loading){

        return <div><MyLoader /></div>
    }
  // this.history.push("/foo")
    
    return(
     <div>
       
         
         {
        
         loading && tags =="" ? <div style={{marginTop: "100px"}}><MyLoader /></div> :
        <> 
        {/*<Customad id={"div-gpt-ad-1651993048954-0"} slot="/1002554/Myjoyonline/myjoyonline_article_top" />*/}
  <StoryItem itemref={newsitem.id} id={newsitem.id} title={newsitem.title} content={newsitem.content} storyimage={storyimage} tags={tags} datepublished={newsitem.dateGmt} storyurl={"https://www.myjoyonline.com/"+slug} storyid={id} slug={slug} source={newsitem.source} display={true}/>
            
  <Customad id={id} slot="/1002554/Myjoyonline/myjoyonline_home_feed_native" />
            
              {<RelatedStories tags={tags}  slugid={slug} storyid={id} />}
           
         </>

        }
         

     </div>
    )  
}

export default Single;