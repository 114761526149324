import {Link} from 'react-router-dom';
import React, {useState, useEffect, useRef, useContext } from 'react';
import Icon from '@mdi/react';
import {mdiChatOutline } from '@mdi/js';
import {mdiTwitter } from '@mdi/js';
import {mdiWhatsapp } from '@mdi/js';
import {mdiEmailOutline } from '@mdi/js';
import GoogleAds from './googleads';
import DOMPurify from 'dompurify';
import ShareButtons from './socialshare';



const  FeedItem = React.forwardRef((props, ref)=>{
 let baseurl = window.location.origin
var n = 0;
//const [listcat, setListcat] = useState([]);
const [listvalue,setListValue] = useState([]);
    
const Listify = (tg) => {

    let items = tg.split(",");

    return items;
    
}

const [items, setItems] = useState([]);
const [host, setHost] = useState("myjoyonline.com")
const [articleid, setArticleid] = useState(props.id)


useEffect(()=>{
  //fetchnews();
},[articleid]);


const fetchnews = async() => {
 
 // const data = await fetch(`https://api.vuukle.com/api/v1/Articles/getEngagementStatsByArticleIds?host=${host}&articleIds=${articleid}`);
  //const items = await data.json();   
  //setItems(items);

}

 
  useEffect(()=>{
    setListValue(Listify(props.tags));
    //setListcat(Listify(props.category))
  },[props.tags, props.category]);



    return( 
    
    
    <div>
      
      {
             
            <div ref={ref} className="feeditem">
        <Link to={{pathname:"/"+props.storyurl.slice(0,props.storyurl.length-1)}}>
            <div className="categoryLabel"><span>{props.datepublished}</span><span>
            
            {
              props.category.map((lcat,index)=>{

                if(lcat.catname !== "" && lcat.catname.search("HP ") == -1 && index < props.category.length-1){



                  return(
                    <>
                          <Link key={index} to={{pathname:"/"+lcat.catpath.toLowerCase() ,  state:{category:lcat.catname}}}>{lcat.catname}</Link><span>,</span>
                    </>
                  )
                }else if(lcat.catname.search("HP ") > -1){
                      
                  
                  return

                  
                }else{

                  return(
                  
                  
                  <Link key={index} to={{pathname:"/"+lcat.catpath.toLowerCase() ,  state:{category:lcat.catname}}}>{lcat.catname}</Link>)
                
                }

              })
            
            
            }
            </span>
            
            </div>
            <div className="titleLabel">
              
              <a href={{pathname:"/"+props.storyurl.slice(0,props.storyurl.length-1)}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></a></div>
          
            <div className="tagLabel">{
                    listvalue.map((lst,index) =>{ 
                    
                      if(lst !== ""){
                    
                   return ( <Link key={index} to={{pathname:"/tag/"+lst, state:{tag:lst}}}  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(lst)}}></Link>)
                  }
                      }
                    
                    )
                      
            }</div>

         
            <div className="storyImage img-holder"><Link to={{pathname:"/"+props.storyurl.slice(0,props.storyurl.length-1)}}><img src={props.image} alt={props.title} width="" className="lazy loaded"/></Link></div>
            <div className="storySummary" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.summary)}}></div>

           
            { /*props.category != "promoted" &&
            <div className="interactiveicons">
           <Link to={{pathname:props.storyurl+"#comment"}}><Icon path={mdiChatOutline} title="User Profile" size={1} horizontal vertical rotate={180} color="#48484" /><span style={{fontSize: "10px"}}>{items.data[0].commentCount}</span></Link> 
            <Link to="#" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i><span style={{fontSize: "10px"}}>{items.data[0].shares.sharesFacebook}</span></Link> 
            <Link to="#" className="twitter"><Icon path={mdiTwitter} title="User Profile" size={1} horizontal vertical rotate={180} color="#1da1f2" /><span style={{fontSize: "10px"}}>{items.data[0].shares.sharesTwitter}</span></Link>
            <Link to="#" className="whatsapp"><Icon path={mdiWhatsapp} title="User Profile" size={1} horizontal vertical rotate={180} color="#25d366" /><span style={{fontSize: "10px"}}>{items.data[0].shares.sharesWhatsApp}</span></Link>
            <Link to="#"><Icon path={mdiEmailOutline} title="User Profile" size={1} horizontal vertical rotate={180} color="#484848" /><span style={{fontSize: "10px"}}>{items.data[0].shares.sharesEmail}</span></Link> 
          
            </div>
          */}

          <div className="interactiveicons">

          <ShareButtons title={props.title} url={baseurl+"/"+props.storyurl.slice(0,props.storyurl.length-1)} twitterHandle={"_MsLinda"} tags={listvalue}/>
          </div>
            </Link>
        </div>
        }
        </div>
    );
})



export default FeedItem;