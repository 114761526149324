import {useState, useEffect} from 'react';
import axios from "axios";

export default function usePlaylistItem(channelId, maxresults, apikey, playlistid){
    let ismount = true
    const [playlist, setPlaylist] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

   

    
    useEffect(() => {

        if(playlist.length > 0){

            //console.log(playlist)
        }
    
        //channelId=&key=AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U
        
        setLoading(true)
        setError(false)
        let cancel
        
        axios({
            method: 'GET',
            url: 'https://youtube.googleapis.com/youtube/v3/playlistItems',
            params:{
            order:"date",
            channelId:channelId,
            playlistId: playlistid,
            maxResults:maxresults,
            key:apikey,
            part : "snippet,contentDetails,status",
            
            },

            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res =>{
            if(ismount){
            setPlaylist(prevPlaylist => {return prevPlaylist, res.data.items});
            setLoading(false)
            }
        }).catch(e => {
            if(axios.isCancel(e)){

                console.log("Error loading data from youtube api")
            } 
            setError(true) 
            setLoading(false)
            console.log("error loading youtube api")
        })

    },[]);

    
return { playlist, error, loading }
}