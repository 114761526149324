import {React, useEffect} from 'react';
import { Link } from 'react-router-dom';

function Ads(props){

  const googlescript = () =>{

   var  sd = document.createElement("script")
   var txtn = document.createTextNode("googletag.cmd.push(function() { googletag.display('"+ props.id +"'); });")
   sd.appendChild(txtn)
   var container = document.getElementById(props.id)
    container.appendChild(sd)
    
  }

  useEffect(()=>{

    googlescript()

  },[])

    return(

<div data-ad-slot={props.slot} id={props.id} style={{display:"flex !important", justifyContent:"center"}}> 

</div>


    )
}

export default Ads;