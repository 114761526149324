import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom';
import luvfm from '../images/luvfm.jpg';
import adomfm from '../images/adomfm.jpg';
import joyfm from '../images/joyfm.jpg';
import hitzfm from '../images/hitzfm.jpg';
import nhyirafm from '../images/nhyirafm.jpg';
import asempafm from '../images/asempafm.jpg'



function NhyiraFm(){
    const location = useLocation();
   // const stationurl = location.state?.stationrul
    //const stationname = location.state?.name;
    let baseurl = window.location.origin


    const getplayer = ()=>{
        //console.log(stationurl)
        var scr = document.createElement("script")
        scr.src = "https://sgplayer.streamguys1.com/atunwa/myjoyonline/mgl/nhyirafm/embed.min.js?v=3.1.0c"
        scr.id = "sgplayerembed"

        document.head.appendChild(scr)
       // var sc = document.getElementById("sgplayerembed")
        //sc.async = true
        //sc.src = "https://sgplayer.streamguys1.com/atunwa/myjoyonline/mgl/adomfm/embed.min.js?v=3.1.0c"
    }

    useEffect(()=>{

        getplayer()

    },[])


return(

    <div>

        <h1>Nhyira Fm</h1>

        <div className="sgplayer-embed" style={{width:"100%", height:"550px"}}></div>
        
       
        <div className="radiolinks">
            
            <a className="jy" href={baseurl+"/radio"}><img src={joyfm} /></a>
            <a className="ad" href={baseurl+"/adomfm"}><img src={adomfm} /></a>
            <a className="as" href={baseurl+"/asempafm"}><img src={asempafm} /></a>
        </div>

        <div className="radiolinks">
        <a className="lu" href={baseurl+"/luvfm"}><img src={luvfm} /></a>
            <a className="nh" href={baseurl+"/nhyirafm"}><img src={nhyirafm} /></a>
            <a className="hi" href={baseurl+"/hitzfm"}><img src={hitzfm} /></a>
            
        </div>
    </div>
)

}

export default NhyiraFm;