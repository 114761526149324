import { useContext, useState, useEffect, Suspense } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import './App.css';
import Album from './components/album.js';
import {BrowserRouter as Router, Switch , Route } from 'react-router-dom';
import NavBar from './components/navbar.js';
import NewsFeed from './components/newsfeed.js';
import TopBar from './components/topbar.js';
import SideBar from './components/sidebar';
import Myloader from './components/myjoyloader.js';
import Single from './components/single.js';
import  Bottombar from './components/bottombar.js';
import Gallery from './components/gallery.js';
import Videos from './components/videos.js';
import liveTv from './components/livetv.js';
import Podcast from './components/podcast.js';
import Radio from './components/radio.js';
import Scrolltotop from './components/scrolltotop.js';
import Tags from './components/tags.js';
import  { Cookies, CookiesProvider } from 'react-cookie';
import Adomfm from './components/adomfm.js';
import Asempafm from './components/asempafm';
import LuvFm from './components/luvfm';
import HitzFm from './components/hitzfm';
import NhyiraFm from './components/nhyirafm';
import Terms from './components/terms.js';
import PrivacyPolicy from "./components/privacy.js";

window.$searchterm = ""
function App({ match }) {

  const errorLink = onError(({graphqlErrors, networkError})=> {

    if(graphqlErrors){

        graphqlErrors.map(({ message, location, path }) => {
            
            alert(`Grapghql error ${message}`);
        });
    }
}) 


const vk = () => {
  var d = document
     // s = d.createElement('script');
  //s.async = true;
  //s.src = 'https://cdn.vuukle.com/platform.js';
  //(d.head || d.body).appendChild(s);

 var  dd = d.createElement('script')
  dd.async = true
  dd.src = ""
  dd.id = "outbrainscript"
  d.head.appendChild(dd)
};

useEffect(()=>{

 

vk()


},[])
  
  //const [items, setItems] = useState([]);

  const link = from([
    errorLink,
    new HttpLink({uri: 'https://www.myjoyonline.com/graphql'})
])
const mjoclient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,
  
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming];
            },
          }
        }
      }
    }}
  ),
  link: link,
});


 const [menud, setMenud] = useState(false);
 const [searchd,setSearchd] = useState(false)
 const [storyid,setStoryid] = useState(0)
 
  return (
    <div className="container">
      
      <Router>
      <Scrolltotop>
      <div className="row">
      <ApolloProvider client={mjoclient}> 
    <TopBar />
        <NavBar />

       <CookiesProvider>     
       <div className="col-lg-6" style={{backgroundColor:"#f7f9f9", paddingTop:"52px"}}>
       <Switch>
        
    <Route path="/" exact component={NewsFeed} />
     <Route path="/entertainment" exact component={NewsFeed} />
     <Route path="/business" exact component={NewsFeed} />
      <Route path="/newsfeed" exact component={NewsFeed} />
      <Route path="/lifestyle" exact component={NewsFeed} />
      <Route path="/health" exact component={NewsFeed} />
      <Route path="/politics" exact component={NewsFeed} />
      <Route path="/opinion" exact component={NewsFeed} />
      <Route path="/sports" exact component={NewsFeed} />
      <Route path="/technology" exact component={NewsFeed} />
      <Route path="/news" exact component={NewsFeed} />
      <Route path="/news/*" exact component={NewsFeed} />
      <Route path="/lifestyle/*" exact component={NewsFeed} />
      <Route path="/sports/*" exact component={NewsFeed} />
      <Route path="/entertainment/*" exact component={NewsFeed} />
     <Route path="/business/*" exact component={NewsFeed} />
      <Route path="/podcast" exact component={Podcast} />
      <Route path="/podcasts" exact component={Podcast} />
      <Route path="/radio" exact component={Radio} />
      <Route path="/album" exact component={NewsFeed} />
      <Route path="/gallery" exact component={Gallery} />
      <Route path="/videos" exact component={Videos} />
      <Route path="/livetv" exact component={liveTv} />
      <Route path="/terms" exact component={Terms} />
      <Route path="/adomfm" exact component={Adomfm} />
      <Route path="/asempafm" exact component={Asempafm} />
      <Route path="/luvfm" exact component={LuvFm} />
      <Route path="/hitzfm" exact component={HitzFm} />
      <Route path="/nhyirafm" exact component={NhyiraFm} />
      <Route path="/tag/*" exact component={Tags} />
      <Route path={`/:id`} exact component={Single} /> 
      <Route path="/privacy" exact component={PrivacyPolicy} />
      </Switch>
      </div>
      </CookiesProvider>
<div className="col-lg-4" style={{borderLeft:"1px solid #eeeeee"}}>
   {<SideBar home={""} />}
      </div>
      </ApolloProvider>
    </div>
    <Bottombar menudisplay={menud} searchdisplay={searchd}/>
    </Scrolltotop> 
    </Router>
   
    </div>
  
   
  );


}
export default App;
