import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

function Discover(){

    useEffect(()=>{
        fetchnews();
      },[]);
  
      
    const [items, setItems] = useState([]);

    const fetchnews = async () =>{
      
        //const data = await fetch('https://www.myjoyonline.com/wp-json/wp/v2/tags?taxonomy=post_tag&order_by=count&order=desc');
        //const data = await fetch('https://www.myjoyonline.com/wp-json/wordpress-popular-posts/v1/popular-posts?freshness=0&range=last30days&limit=5');
        const data = await fetch('https://www.myjoyonline.com/wp-json/mjo/v1/trendingstories?freshness=0&range=last30days&limit=15');
        const items = await data.json();   
         //console.log(items);
         setItems(items);
      
     }

    return(
     
    <div style={{marginTop:"20px", bottom:"10px", width:"300px", padding:"20px",backgroundColor:"#f7f9f9",borderRadius:"20px"}}>
        <div className="sitelabels" style={{fontWeight:"500"}}>Discoveries</div>
        {items.map((item,index) => (


                
                


   <Link className="discover" key={index+item.tag} to={{pathname:"/tag/"+item.tag, state:{tag:item.tag}}}>{item.tag}</Link> 



        ))}
        </div>
    
    );
    }
    
export default Discover;