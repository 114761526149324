import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../joylogo.png';
import Icon from '@mdi/react';

function Bottombar(props){


  const [menu,setMenu] = useState(props.menudisplay);
  const [search,setSearch] = useState(props.searchdisplay);

  const menuhandler = () =>{

    if(menu === false){
          setMenu(true)
          setSearch(false)
  }else{
      setMenu(false)
  }
  }


  const searchhandler = () =>{

    if(search === false){
    setSearch(true)
    setMenu(false)
    }
    else{
    setSearch(false)
    }
  }

    return(
<div className="row position-fixed zero-padding show-mobile" >
    <div className={search? "showm container": "hidem"}>
        <form style={{height: "100vh"}}>

            <input type="text" placeholder="search..." style={{marginTop:"20px", width:"100%", border: "1px solid #cccccc", borderRadius: "10px", padding: "5px"}}/>
        </form>

    </div>

<div className={menu? "showm container" : "hidem"}>
    <header>
       
        <nav className="navbar" style={{marginTop: "20px"}}>
        <ul className="navbar-nav" style={{width:"100%"}}>
            <li className="nav-item">
            <Link to="/newsfeed">
                <i className="far fa-newspaper"></i>News Feed
                </Link>
            </li>
            
            
            <li className="nav-item"><Link to="/entertainment">
               <i className="far fa-smile"></i>Entertainment
               </Link>
            </li>
            
            
            <li className="nav-item"><Link to="/politics">
            <i className="fas fa-gavel"></i>Politics
             </Link>
            </li>
           
            
            <li className="nav-item"><Link to="/business">
            <i className="far fa-building"></i>Business
            </Link>
            </li>
            
            
            <li className="nav-item"><Link to="/sports">
            <i className="fas fa-futbol"></i>Sports
             </Link>
            </li>
           
            
            <li className="nav-item"><Link to="/health">
            <i className="fas fa-briefcase-medical"></i>Health
            </Link>
            </li>
           
            
            <li className="nav-item"><Link to="/opinion"><i className="fas fa-paint-brush"></i>Opinion
            </Link>
            </li>
            
            
            <li className="nav-item"><Link to="/lifestyle"><i className="fas fa-suitcase"></i>Lifestyle
            </Link></li>
            

            
            <li className="nav-item"> <Link to="/video"><i className="far fa-play-circle"></i>Video
            </Link></li>
            
            
            <li className="nav-item"><Link to="/africa"><i className="fas fa-star"></i>Africa
            </Link></li>

            <li><a href=".">... more ...</a></li>
            
        </ul>
        
        </nav>
        </header>
    </div>


  <div className="container bottombar zero-padding" style={{textAlign:"center"}}>
    
   
     <Link to="/"><i className="fa-solid fa-house"></i><br />Home</Link>
     <Link to="#" onClick={searchhandler}><i className="fas fa-search"></i><br /> Search</Link>
     <Link to="#" onClick={menuhandler}><i className="fas fa-bars"></i><br/>Menu</Link>
     </div>
  </div>


        
    )
}

export default Bottombar;