import ReactPlayer from "react-player";

export default function LiveStream(){


    return(

        <div style={{margin:"20px auto", maxWidth: "300px"}}>
       <ReactPlayer url={"https://www.dailymotion.com/embed/video/x7w7197"} height="200px" width="300px" controls={true} pip={true} stopOnUnmount={false} />
       <br/>
    <ReactPlayer url={"https://www.dailymotion.com/embed/video/x824i7c"} height="200px" width="300px" controls={true} pip={true} stopOnUnmount={false} />
        </div>
    )
}