import { useEffect, useState } from "react";
import Ads from "./ads";
import GoogleAds from "./googleads";



function Customad(props){

  //const [adcon, setAdcon] = useState(Math.floor(Math.random()*200000000));
  const dyad = () =>{
   
    var sddd = document.createElement("script")
    var ud = document.getElementById("div-gpt-ad-164543556533-0-" + props.id)
      var txtnode = document.createTextNode("googletag.cmd.push(function() {"+
        "var scrollslot = googletag.defineSlot('"+props.slot+"', [[300, 250], [336, 280], 'fluid'])"+
          ".setTargeting('refresh', 'true').addService(googletag.pubads());"+

        "var div = document.createElement('div');"+
        "div.id = scrollslot.getSlotElementId(); document.getElementById('div-gpt-ad-164543556533-0-"+props.id+"').appendChild(div);"+
       "googletag.display(scrollslot);}); ")
       sddd.appendChild(txtnode)
       document.body.appendChild(sddd)

  }

 

  
  useEffect(()=>{
    
      dyad();

  },[])
  
return( 
    
    
    <div>


      
     
        
        <div className="_feeditem" id={"div-gpt-ad-164543556533-0-" + props.id} style={{background: "transparent !important", padding: "0px 15px 15px 0px", border: "none", float: props.style,}}>
         
         </div>
      
        </div>
    );
}

export default Customad

