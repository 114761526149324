import React from 'react';
import CurrencyConverter from './currencyConverter';

function Forex(){

    return(
        <>
         <div className="popular-trending">
        <div className="sitelabels" style={{paddingLeft:"15px", marginTop:"15px",fontWeight:"500"}}>Forex</div>
        {
            
            <div style={{width:"100%"}}>
                <CurrencyConverter />
               {/* <ForexCrossRates  width="290" height="350" colorTheme="dark" currencies={["EUR", "USD", "GBP"]}></ForexCrossRates >*/}

            </div>
        
        }
        </div>

        </>
    )
}

export default Forex