import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import {mdiChatOutline } from '@mdi/js';
import {mdiTwitter } from '@mdi/js';
import {mdiWhatsapp } from '@mdi/js';
import {mdiEmailOutline } from '@mdi/js';
import ReactPlayer from 'react-player';
import DOMPurify from 'dompurify';
import ShareButtons from './socialshare';






function Video(props){

    const doplay = () =>{

      
    }

    return(

        <>
        


        <div className="row feeditem" style={{width:"100%", margin:"10px auto", position:"relative", display: "flex", justifyContent: "center"}}>
            <Link onClick={doplay()} style={{display:"block", width:"100%"}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", width:"100%"}} className="storyImage">
                <div style={{background: "#000000a9", position: "absolute", color: "#ffffff", border: "1px solid #ffffff",
    borderRadius: "200px", padding: "6px 18px", fontSize: "28px"}}><i className='fa fa-play'></i></div>
                {/*<img src={props.thumbnail} style={{width:"100%"}} />*/}
            </div>
       <div style={{position:"relative", display:"block"}}>
           <ReactPlayer url={props.video} controls={true} light={"https://i4.ytimg.com/vi/"+props.vid+"/hqdefault.jpg"} width="100%" pip={true} stopOnUnmount={false} playing />
           </div>
       <div className="titleLabel" style={{padding: "20px 0px", color:"#333333", width:"100%"}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></div>
       </Link>
        <div className="interactiveicons">


        <ShareButtons title={props.title} url={props.video} twitterHandle={"_MsLinda"} tags={props.title}/>
        
        </div> 
        
        </div>

        </>
    )
}

export default Video;