import logo from '../joylogo.png';
import '../App.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Search from './search';

function NavBar(){

   //const [searchstyle,setSearchstyle] = useState({})
   const [searchlabel, setSearchlabel] = useState(false)
   const [searchterm,setSearchterm] = useState("") 
   const [sresult,setSresult] = useState("")
   const loc = useLocation();

   let menu = loc.state?.category;

   const handlesearch = (e)=>{

    e.preventDefault()
    setSresult(searchterm)
        
    }

    const searctm = (e) =>{

        setSearchterm(e.target.value)
    }
return(
    <div className="col-lg-2 hide-mobile" style={{borderRight:"1px solid #eeeeee"}}>
       
        <div className={searchlabel? "showelement searchoverlay":"hideelement"} onClick={()=>setSearchlabel(false)}></div>

    <div className="position-fixed" style={{height:"100vh", padding:"10px 10px 0px 0px", zIndex:"200"}}>
   
    <header>
        <Link to={{pathname:"/", state:{category:null}}}><img src={logo} width="80px" alt="Site Logo "/></Link>
        <nav className="navbar" style={{marginTop: "5px"}}>
        <ul className="navbar-nav">
        <form onSubmit={handlesearch}>
            <span className={searchlabel? "showelement":"hideelement"} style={{position:"absolute", fontWeight:"700", fontSize:"18px", padding:"10px"}}><i className="fa fa-search" aria-hidden="true" style={{marginRight:"13px"}}></i>Search:</span>
            <input type="text" placeholder='search' onClick={()=>setSearchlabel(true)} className={searchlabel?"searchinput searchinputshow":"searchinput"} onChange={searctm} value={searchterm} />
            
            </form>

          {sresult != "" && <Search search={sresult} hideshow={searchlabel} />}
            <li className="nav-item">
            <Link to={{pathname:"/newsfeed", state:{category:null}}} className={menu === null?"activelink":""}>
                <i className="far fa-newspaper"></i>News Feed
                </Link>
            </li>


            <li className="nav-item">
                <Link to={{pathname:"/politics", state:{category:"politics"}}} className={menu === "politics"?"activelink":""}>
            <i className="fas fa-gavel"></i>Politics
             </Link>
            </li>
           
            
            <li className="nav-item">
                <Link to={{pathname:"/business", state:{category:"business"}}} className={menu === "business"?"activelink":""}>
            <i className="far fa-building"></i>Business
            </Link>
            </li>
            
            
            <li className="nav-item">
                <Link to={{pathname:"/entertainment", state:{category:"entertainment"}}} className={menu === "entertainment"?"activelink":""}>
               <i className="far fa-smile"></i>Entertainment
               </Link>
            </li>
            
            
         
            <li className="nav-item">
                <Link to={{pathname:"/sports", state:{category:"sports"}}} className={menu === "sports"?"activelink":""}>
            <i className="fas fa-futbol"></i>Sports
             </Link>
            </li>
           
            
            <li className="nav-item">
                <Link to={{pathname:"/health", state:{category:"health"}}} className={menu === "health"?"activelink":""}>
            <i className="fas fa-briefcase-medical"></i>Health
            </Link>
            </li>
           

            <li className="nav-item">
                <Link to={{pathname:"/technology", state:{category:"technology"}}} className={menu === "technology"?"activelink":""}>
                    <i class="fas fa-code-branch"></i>Technology
            </Link>
            </li>

            
            <li className="nav-item">
                <Link to={{pathname:"/opinion", state:{category:"opinion"}}} className={menu === "opinion"?"activelink":""}>
                    <i className="fas fa-paint-brush"></i>Opinion
            </Link>
            </li>
            
            
            <li className="nav-item">
                <Link to={{pathname:"/lifestyle", state:{category:"lifestyle"}}} className={menu === "lifestyle"?"activelink":""}>
                <i className="fas fa-suitcase"></i>Lifestyle
            </Link></li>
       
            <li className="nav-item">
                <Link to={{pathname:"/gallery", state:{category:"gallery"}}} className={menu === "gallery"?"activelink":""}>
                <i className="fa fa-picture-o" aria-hidden="true"></i>Gallery
            </Link></li>

           {/*<li><a href=".">... more ...</a></li>*/}
            
        </ul>
        
        </nav>
        </header>


        <hr style={{maxWidth:"150px", align:"left"}} />

        
<div style={{margin:"10px auto", paddingTop:"0px"}}>
    <a href="https://podcasts.google.com/?q=Multimedia%20Ghana&amp;hl=en-GH" target="_blank" class="podcast-footer-links" style={{display:"block", color:"#000",margin:"5px 0px", padding:"4px 12px"}}>
<img className="store" style={{height: "21px"}} src="https://www.myjoyonline.com/wp-content/uploads/2021/03/google-podcast-logo.png" alt="google podcast" />&nbsp;Google Podcast
</a>
<a href="https://tunein.com/radio/Multimedia-Group-a39902/" target="_blank" class="podcast-footer-links" style={{display:"block", color:"#000",margin:"5px 0px", padding:"4px 12px"}}>
<img className="store" style={{height: "21px"}} src="https://www.myjoyonline.com/wp-content/uploads/2020/04/tune-in-abb.png" alt="tune in radio" />&nbsp;TuneIn
</a>
<a href="https://podcasts.apple.com/gh/podcast/joy-fm-super-morning-show/id1450268647" target="_blank" className="podcast-footer-links" style={{display:"block", color:"#000",margin:"5px 0px", padding:"4px 12px"}}><img className="store" style={{height: "21px"}} src="https://www.myjoyonline.com/wp-content/uploads/2020/04/itunes-apple.png" />&nbsp;iTunes</a>
<a href="https://open.spotify.com/search/multimedia%20ghana" className="podcast-footer-links" style={{display:"block", color:"#000",margin:"5px 0px", padding:"4px 12px"}}><img class="store" style={{height: "21px"}} src="https://www.myjoyonline.com/wp-content/uploads/2021/03/spotify-logo.png" />&nbsp;Spotify</a>

<a href="https://www.pandora.com/search/multimedia%20ghana/podcasts" className="podcast-footer-links" style={{display:"block", color:"#000",margin:"5px 0px", padding:"4px 12px"}}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="23" height="23"><path fill="#6569D4" fill-rule="evenodd" d="M5,2.5c-0.27614,0 -0.5,0.22386 -0.5,0.5v18c0,0.2761 0.22386,0.5 0.5,0.5h6c0.2761,0 0.5,-0.2239 0.5,-0.5v-5.5h1.5c3.5899,0 6.5,-2.9101 6.5,-6.5c0,-3.58985 -2.9101,-6.5 -6.5,-6.5h-8Z"/></svg><span style={{position:"relative", top:"-3px"}}> Pandora</span> 
    </a>
    </div>
    </div>
</div>

    
    
);
}

export default NavBar;