import React, { useEffect, useState }  from "react";
import CurrencyRow from "./CurrencyRow";

import ca from './assets/img/ca.png';
import cn from './assets/img/cn.png';
import eu from './assets/img/eu.png';
import gb from './assets/img/gb.png';
import gh from './assets/img/gh.png';
import jp from './assets/img/jp.png';
import ng from './assets/img/ng.png';
import us from './assets/img/us.png';
import za from './assets/img/za.png';

const fx = {
    USD: {
        title: "Unted States Dollars",
        abrev: "USD",
        flag: us,
        symbol: "$"
    },
    GHS: {
        title: "Ghanaian Cedi",
        abrev: "GHS",
        flag: gh,
        symbol: "₵"
    },
    NGN: {
        title: "Nigerian Niara",
        abrev: "NGN",
        flag: ng,
        symbol: "₦"
    },
    ZAR: {
        title: "South African Rand",
        abrev: "ZAR",
        flag: za,
        symbol: "R"
    },
    CAD: {
        title: "Canadian Dollar",
        abrev: "CAD",
        flag: ca,
        symbol: "$"
    },
    EUR: {
        title: "Euro",
        abrev: "EUR",
        flag: eu,
        symbol: "€"
    },
    GBP: {
        title: "British Pounds",
        abrev: "GBP",
        flag: gb,
        symbol: "£"
    },
    JPY: {
        title: "Japanese Yen",
        abrev: "JPY",
        flag: jp,
        symbol: "¥"
    },
    CNY: {
        title: "Chinese Yuan",
        abrev: "CNY",
        flag: cn,
        symbol: "¥"
    }
};
const MNT = ["January", "February", "March", "April" ,"May", "June", "July", "August", "September", "October", "November", "December"];

const SYMBOLS = 'USD,GHS,NGN,ZAR,CAD,EUR,GBP,JPY,CNY';
const BASE_URL = `https://api.exchangerate.host/latest?base=USD&symbols=${SYMBOLS}`;

export default function CurrencyConverter() {

    const [currencyOptions, setCurrencyOptions] = useState([])

    const [fxRates, setFxRates] = useState([]);


    const [usdAmount, setUsdAmount] = useState(1)
    const [ghsAmount, setGhsAmount] = useState()
    const [ngnAmount, setNgnAmount] = useState()
    const [zarAmount, setZarAmount] = useState()
    const [cadAmount, setCadAmount] = useState()
    const [eurAmount, setEurAmount] = useState()
    const [gbpAmount, setGbpAmount] = useState()
    const [jpyAmount, setJpyAmount] = useState()
    const [cnyAmount, setCnyAmount] = useState()

    const [toCurrency, setToCurrency] = useState()
    const [exchangeRate, setExchangeRate] = useState()
    const [amount, setAmount] = useState(1)
    const [amountInFromCurrency, setAmountInFromCurrency] = useState(true)

    const onChangeUsdAmount = (e) => {
        setUsdAmount(e.target.value);
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeGhsAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['GHS']));
        setGhsAmount(e.target.value);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
        
    }
    const onChangeNgnAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['NGN']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeZarAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['ZAR']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value );
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeCadAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['CAD']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeEurAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['EUR']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeGbpAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['GBP']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeJpyAmount = (e) => {
        setUsdAmount( e.target.value * (1 / fxRates['JPY']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value );
        setCnyAmount(e.target.value * fxRates['CNY']);
    }
    const onChangeCnyAmount = (e) => {
        setUsdAmount(e.target.value * (1 / fxRates['CNY']));
        setGhsAmount(e.target.value * fxRates['GHS']);
        setNgnAmount(e.target.value * fxRates['NGN']);
        setZarAmount(e.target.value * fxRates['ZAR']);
        setCadAmount(e.target.value * fxRates['CAD']);
        setEurAmount(e.target.value * fxRates['EUR']);
        setGbpAmount(e.target.value * fxRates['GBP']);
        setJpyAmount(e.target.value * fxRates['JPY']);
        setCnyAmount(e.target.value);
    }

    useEffect(() => {
        fetch(BASE_URL)
            .then(res => res.json())
            .then(data => {
                const firstCurrency = Object.keys(data.rates)[0]
                setCurrencyOptions([data.base, ...Object.keys(data.rates)])
                setExchangeRate(data.rates[firstCurrency])
                setFxRates(data.rates);
                setUsdAmount(data.rates['USD']);
                setGhsAmount(data.rates['GHS']);
                setNgnAmount(data.rates['NGN']);
                setZarAmount(data.rates['ZAR']);
                setCadAmount(data.rates['CAD']);
                setEurAmount(data.rates['EUR']);
                setGbpAmount(data.rates['GBP']);
                setJpyAmount(data.rates['JPY']);
                setCnyAmount(data.rates['CNY']);    

            })
    }, []);

    const dt = new Date().toLocaleDateString();
    const dtd = dt.split("/");

    return (
        <div className="fx_widget">

            <CurrencyRow
                currency={fx['USD']}
                amount={usdAmount}
                onChangeAmount={onChangeUsdAmount}
            />
            <CurrencyRow
                currency={fx['GHS']}
                amount={ghsAmount}
                onChangeAmount={onChangeGhsAmount}
            />
            <CurrencyRow
                currency={fx['NGN']}
                amount={ngnAmount}
                onChangeAmount={onChangeNgnAmount}
            />
            <CurrencyRow
                currency={fx['ZAR']}
                amount={zarAmount}
                onChangeAmount={onChangeZarAmount}
            />
            <CurrencyRow
                currency={fx['CAD']}
                amount={cadAmount}
                onChangeAmount={onChangeCadAmount}
            />
            <CurrencyRow
                currency={fx['EUR']}
                amount={eurAmount}
                onChangeAmount={onChangeEurAmount}
            />
            <CurrencyRow
                currency={fx['GBP']}
                amount={gbpAmount}
                onChangeAmount={onChangeGbpAmount}
            />
            <CurrencyRow
                currency={fx['JPY']}
                amount={jpyAmount}
                onChangeAmount={onChangeJpyAmount}
            />
            <CurrencyRow
                currency={fx['CNY']}
                amount={cnyAmount}
                onChangeAmount={onChangeCnyAmount}
            />
            
            <div><span className="fx_footer">{MNT[dtd[0]-1] + ", " + dtd[1] + " " + dtd[2]}</span></div>
        
        </div>
    );
}