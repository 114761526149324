import { useQuery } from '@apollo/client';
import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import Album from './album';
import useGalleryList from './gallerylist';


function Gallery(){
    let ismount = true
    const storyid = "10032143885";
    
    const {photoitems, error, loading} = useGalleryList(storyid)
   // const [items,setItems] = useState({})
    //console.log(data)

  /* useEffect(()=>{
        
    if(ismount){}
       // setItems(data)
        //console.log(photoitems) 
        //ismount = true
        
    },[photoitems])*/
   

   /* if(loading){
    return(

        <>

        <div>Please wait whiles we load the gallery...</div>
        
        
        </>
    )
    }

    if(error){

        return(


            <div>Error Loading Gallery</div>
        )
    }
   
*/
       const items = (photoitems.items) 
    
        //setItems(data.photoGallery)
        return(

            <>
            {<Album title={photoitems.title} photos={items}/>}
            
            </>
        )
    

}

export default Gallery;