import FeedItem from './feeditem.js';
import { useEffect, useState, useRef, useCallback } from 'react';
//import Topstory from './topstory.js';
import useItemsList from './itemslist.js';
import MyLoader from './myjoyloader.js';
import Album from './album.js';
import { useLocation } from 'react-router-dom';
//import usePlayList from './useplaylist.js';
import Video from './video.js';
import Customad from './customad.js';
//import GoogleAds from './googleads.js';
//import VideoReel from './videoreel.js';
import Recommended from './related.js';
import { useCookies } from 'react-cookie';
import useVideosList from './videolist.js';

function NewsFeed(match){

    const location = useLocation();
    const [categories,setCategories] = useState();
    const [pvcursour, setPvcursour] = useState();
    const [page, setPage] = useState(10);
    let ismount = true
    let vindex = -1;
   // const [vindex, setVindex] = useState(0);
    let pagemeta = location.state?.category;
    var items_more = 10;
    
//console.log(vindex)

    const getnewstories = () =>{

       
        fetchMore({ variables: { categoryName: "", first: 200, before: pvcursour },
            updateQuery
          });

          if(items.length > 0 ){

            console.log(items.length)
          }else{

            console.log("no new stories yet");
          }

        setInterval(getnewstories, 500000)
    }

   useEffect(()=>{

       // getnewstories();
    },[pagemeta]) 


    useEffect(() => {

            setPage(20);
        if(ismount){
       fetchMore({ variables: { categoryName: pagemeta, first: page, after: null },
            updateQuery
          });
         // prevNewsitems => {return prevNewsitems, res.data}
        setCategories(pagemeta);

          
        }

        setPvcursour(newsitems.startCursor)
        items_more = 10;

        //getnewstories();
          

    }, [location.state?.category])
    
   

    const { newsitems, error, loading, items, fetchMore , updateQuery} = useItemsList({variables:{ first: 1, after: null, categoryName: categories}});
    // Get Video
    //let  apikey = "";
    //let channelId = "UChd1DEecCRlxaa0-hvPACCw";
   // const { videoitems, verror, vloading } = usePlayList(channelId, 20, apikey);
    const { videoitems } = useVideosList("UChd1DEecCRlxaa0-hvPACCw", 200, "AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U");
    //const {videos, setVideos} =  useState();
    //let vmounted = false

 /* useEffect(()=>{

        if(videoitems.error != true && ismount){
     
    }

    },[videoitems]) */

    
    const stringify = (dgarray) =>{
        let ttg  = "";
        for(let i=0; i < dgarray.length; i++){

            if(i < dgarray.length-1){

                ttg+= dgarray[i].name+",";
            }else{
                ttg+= dgarray[i].name
            }   
        }
        return ttg;
    }

    const catObject= (dgarray) =>{
        let ttg  = [];
        for(let i=0; i < dgarray.length; i++){

            
                if(dgarray[i].parent != null){
                    
                    ttg[i] = {

                        "catname" : dgarray[i].name,
                        "catpath" : dgarray[i].parent.node.name +"/" + dgarray[i].name,
                    }
                    

                }else{
                    ttg[i] = {

                        "catname" : dgarray[i].name,
                        "catpath" : "./" + dgarray[i].name,
                    }

                }
                  
        }
        return ttg;
    }


   
  
    const observer = useRef();
   
    const itemRef = useCallback(node => {

        if (loading) {return <div style={{width:"100%"}}>{ loading && <MyLoader /> }</div>}
        if(observer.current)observer.current.disconnect()

        observer.current = new IntersectionObserver(entries =>{

            if(entries[0].isIntersecting){

                if(ismount){
                setPage(prevPage => prevPage + 20)
                
                }
                 
    fetchMore({ variables: { categoryName: pagemeta, first: page, after: newsitems.startCursor },
        updateQuery
      });
    
      items_more +=20;
             
            
            }
        })

        if(node) observer.current.observe(node)
    }, [loading, error, newsitems, items])
    
    const time_ago = (datepublished) =>{
   
         var datepub = new Date(datepublished)
         var datecurrent = new Date()
         datecurrent = datecurrent.getTime()
 
         var datedif = datecurrent - (datepub.getTime());
 
         var tm = Math.floor(datedif / 60000);

         if(Math.floor(tm/(43200*12)) > 0){
 
            return Math.floor(tm/(43200*12)) + " years ago";

        }else if (Math.floor(tm/(1440*30)) > 0){
 
            return Math.floor(tm/(1440*30)) + " months ago";
        }else if(Math.floor(tm/(1440*7)) > 0){
 
            return (Math.floor(tm/(1440*7))) + " weeks ago";
        }else if(Math.floor(tm / 1440) > 0){
 
            return (Math.floor(tm/1440)) + " day ago"
        }else if(Math.floor(tm) > 60){
            let hrago = Math.floor (Math.floor(tm) / 60);
            let minago = Math.floor(tm - (hrago * 60))
            if(hrago > 24 ){

                return (Math.floor(hrago /24)) + " days ago"
            }
            return hrago + "hr " + minago + " min ago"

        }else if(Math.floor(tm) < 60){
 
            return Math.floor(tm) + " min ago"

        }else if(tm <= 0 ){
 
             return "Just Now"
 
         }
 
     }



    let n = 0;
   /* if(videoitems.error){

        console.log(videoitems.error)
    }*/
  //console.log(videoitems)
if(loading){
      
        return  <div style={{width:"100%"}}>{ loading && <MyLoader /> }</div>

}


//console.log(window.location.origin)
 
if(error){

        return <div style={{width:"100%"}}>{ error && 'Error Loading News content Please try again later...' }</div>

}

if(items.length > 0){

    return(
        
        
        <div style={{position: "relative",zIndex:"0",top:"0px"}}>
           {/* <Topstory /> */}
            <div style={{margin:"15px auto"}}>
                {/*<GoogleAds slot="/1002554/Myjoyonline/myjoyonline_home_feed_top" id="myjoyonline_home_feed_top" />*/}
            </div>
        {
            
          
        items.length > 0 && ismount &&
        
        items.map((item, index) => {
            
            if(items.length === index+1){
                return (
                    <>
                <FeedItem ref={itemRef} key={item.node.id} id={item.node.id} datepublished={time_ago(item.node.dateGmt)} title={item.node.title} image={item.node.featuredImage.node.sourceUrl} storyurl={item.node.slug+"/"} tags={stringify(item.node.tags.nodes)} summary={item.node.excerpt} category={catObject(item.node.categories.nodes)}/> 
                </>
                )
            }else{ 

                if((index+1) % 5 === 0){

                    return (
                        <>   
                        <FeedItem  key={item.node.id} id={item.node.id} title={item.node.title} datepublished={time_ago(item.node.dateGmt)} image={item.node.featuredImage.node.sourceUrl} storyurl={item.node.slug+"/"} tags={stringify(item.node.tags.nodes)} summary={item.node.excerpt} category={catObject(item.node.categories.nodes)}/>
                        {/*<Album cls={item.node.id} title={"Mercy Chinwo and fiancé, Pastor Blessed tie the knot"} /> */}                     
                        </>
                    )

                }
                 
                  
                    if(index > 0 && index % 3 === 0 && videoitems.length > 0  ){
                        vindex++;
                        
                    /* <Recommended category={""} />
                    ||
                    vindex < vlength &&
                    <VideoReel playlistid={
                        categories == "entertainment" ? entvideo[vindex].id : categories == "business" ? busvideo[index].id : categories == "sports" ? sportsvideo[vindex].id : videoid[vindex].id 
                    
                    } playlisttitle={
                        categories == "entertainment" ? entvideo[vindex].title : categories == "business" ? busvideo[index].title : categories == "sports" ? sportsvideo[vindex].title : videoid[vindex].title 

                    }/> */
               
                    /*<VideoReel playlistid={videoitems[vindex].id} playlisttitle={videoitems[vindex].snippet.title}/> 
                    <Video video='https://www.youtube.com/watch?v=5W0VlmqbXzo' />  */
                 
                    return (
                        <>
                    <Video video={'https://www.youtube.com/watch?v='+videoitems[vindex].id.videoId} title={videoitems[vindex].snippet.title} vid={videoitems[vindex].id.videoId}  /> 
                        </>
                        )
                       
                 }
                
               
                return(
                <>
                <FeedItem key={item.node.id} id={item.node.id} datepublished={time_ago(item.node.dateGmt)} title={item.node.title} image={item.node.featuredImage.node.sourceUrl} storyurl={item.node.slug+"/"} tags={stringify(item.node.tags.nodes)} summary={item.node.excerpt} category={catObject(item.node.categories.nodes)}/>
                
                {(index % 2 === 0) &&
               <Customad id={item.node.id} slot="/1002554/Myjoyonline/myjoyonline_home_feed_native" />
                          } 
                    </>
                )
                    
                }
            

})

}


</div>


    );

}else{

    return(<div >Loading Stories...Have Patience</div>)
}
    
}

export default NewsFeed;