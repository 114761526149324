import { useEffect } from "react";

function Podcast(){


   /**
 * Converts the JSObject to query string, encode values/params
 * @param {{
 *  group?: string;
    *  podcast?: string;
    * 	producer?: string;
    * }} queryParams
    */
   const jsObjToQueryString = (queryParams) => {
   
       // custom queryParams filter.
       const queryParamsFilter = (key) => {
           const ret = queryParams[key] !== undefined &&
           typeof queryParams[key] === "string"
               ? queryParams[key] !== ""
               : true;
           return ret;
       }
   
       const ret = Object.keys(queryParams)
           .filter(
               queryParamsFilter
           )
           .map((key) => {
               // check if publisher not empty then load group with publisher
               if (queryParams['publisher'] !== '') {
                   // if publisher is not empty load all podcasts for the publisher
                   if (key === 'publisher')
                       return `publisher/${queryParams[key]}`;
                   // if group is not empty load group podcasts of specific publisher
                   if (key === 'group')
                       return `${queryParams[key]}`;
               } else {
                   // if group is not empty load group podcasts
                   if (key === 'group')
                       return `group/${queryParams[key]}`;
                   // if podcast is not empty load episodes of podcast
                   if (key === 'podcast')
                       return `directory/${queryParams[key]}`;
               }
   
           })
           .join("/");
   
       return ret;
   }
   
   /**
    * @param {string} selector
    * @param {{
    *  group?: string;
    *  podcast?: string;
    * 	producer?: string;
    * }} queryParams
    **/
   const loadIFrame = (selector, queryParams = {}) => {
       const url = "https://atunwapodcasts.com/";
   
       // convert Object to query string formate.
       const queryString = jsObjToQueryString(queryParams);
   
       const src = url + (queryString ? `${queryString}?header=on` : "");
       //<embed src="http://stackoverflow.com" width=200 height=200 />
   
       const iframeEl = document.createElement("embed");
   
       // set frame source.
       iframeEl.src = src;
       iframeEl.style.width = '100%';
       iframeEl.style.height = '100%';
   
       const targetEl = getTargetObj(selector);
       targetEl.innerHTML = "";
   
       // add frame to target node.
       targetEl.appendChild(iframeEl);
   };
   /**
    * @param {string} target // where you want to embed iframe
    * @param {string} publisher //publisher id
    * @param {int} group //group id
    * @param {string} episode //group id
    **/
   const embedPodcats = (target, publisher = '', group = 0, episode = '', header = 'off') => {
       let values = {
           publisher: '',
           episode: '',
           group: '',
       };
   
   
       // check for podcast value.
       if (typeof publisher !== 'undefined' && publisher != '') {
           values.publisher = publisher;
       }
   
       // check from grop value.
       if (typeof group !== 'undefined' && group > 0) {
           values.group = group;
       }
   
       // check for producer value.
       if (typeof episode !== 'undefined' && episode != '') {
           values.episode = episode;
       }
   
       // Load frame based on above values.
       loadIFrame(target, values, header);
   };
   
   /**
    * @param {String} target
    **/
   const getTargetObj = (target) => {
       const t = target;
       target = document.querySelector(target);
       
       if (target == null) {
           // create target node.
           target = document.createElement("div");
           const temp = document.createTextNode("Loading...");
           target.appendChild(temp);
   
           // Add id to node.
           target.setAttribute("id", t);
           //document.body.append(target)
           //append the node to body.
           //document.body.appendChild(target);
       }
   
       return target;
   };


   useEffect(()=>{

    {embedPodcats('#atun-podcasts','myjoyonline', 10, '')}

   },[])

return(
  
<div id="atun-podcasts" style={{height:"100vh"}}>


<div>

<h1>

Podcast

</h1>


</div>

        
 

    </div>
)

}

export default Podcast;