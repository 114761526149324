import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import joyprime from '../images/joyprime.jpg';
import joynews from '../images/joynews.jpg';
import adomtv from '../images/adomtv.jpg';



function LiveTv(){

    const location = useLocation()
    const [videourl,setVideourl] = useState(location.state?.vdurl)
    const channel = ""; //location.state?.channel

    const [programs,setPrograms] = useState("")

    useEffect(()=>{

       setVideourl(location.state?.vdurl)


    },[location.state?.vdurl])
   
    
    
    
    
    return(

        
        <div className="row stationbtns" style={{textAlign:"center", padding:"0px 10px"}}>
            
            
            <ReactPlayer url={videourl} controls={true} pip={true} stopOnUnmount={false} playing  />
           <div className="btns">
            <Link className="jn" to={{pathname:"/livetv", state:{vdurl:"https://www.dailymotion.com/embed/video/x7w7197", channel:"jn"}}} >
                        
                        <img src={joynews} alt="Joy News" />
            </Link>
          
            
          
            <Link className="ad" to={{pathname:"/livetv", state:{vdurl:"https://www.dailymotion.com/embed/video/x7w71b8", channel:"ad"}}}>
               
                <img src={adomtv} alt="Adom Tv" />
                
                </Link>
           
            <Link className="jp" to={{pathname:"/livetv", state:{vdurl:"https://www.dailymotion.com/embed/video/x824i7c", channel:"jp"}}}>
               
                <img src={joyprime} alt="Joy Prime" />
                
                </Link>
                </div>

 
                <div className="programs-lineup table-responsive programs-window" style={channel === "jn" ? {display: "block"}: {display: "none"}}>
                <h1>Joy News Programs</h1>
<table className="table">
<tbody><tr><th>Monday</th><th>Tuesday</th><th>Wednesday</th><th>Thursday</th><th>Friday</th><th>Saturday</th><th>Sunday</th></tr>
<tr><td>AM SHOW (Live)<p>5:00am - 7:00am</p></td><td>AM SHOW (Live)<p>5:00am - 7:00am</p></td><td>AM SHOW (Live)<p>5:00am - 7:00am</p></td><td>AM SHOW (Live)<p>5:00am - 7:00am</p></td><td>AM SHOW (Live)<p>5:00am - 7:00am</p></td><td>DW TV<p>5:00am - 7:00am</p></td><td>DW TV<p>5:00am - 7:00am</p></td></tr>
<tr><td>AM SHOW (Live)<p>7:00am - 8:30am</p></td><td>AM SHOW (Live)<p>7:00am - 8:30am</p></td><td>AM SHOW (Live)<p>7:00am - 8:30am</p></td><td>AM SHOW (Live)<p>7:00am - 8:30am</p></td><td>AM SHOW (Live)<p>7:00am - 8:30am</p></td><td>SPORTS REVIEW (Live)<p>7:00am - 8:30am</p></td><td>DW TV<p>7:00am - 8:30am</p></td></tr>
<tr><td>AM SHOW (Live)<p>8:30am - 9:00am</p></td><td>AM SHOW (Live)<p>8:30am - 9:00am</p></td><td>AM SHOW (Live)<p>8:30am - 9:00am</p></td><td>AM SHOW (Live)<p>8:30am - 9:00am</p></td><td>AM SHOW (Live)<p>8:30am - 9:00am</p></td><td>JN EXTRA / SPORTS PACKAGE<p>8:30am - 9:00am</p></td><td>DW TV<p>8:30am - 9:00am</p></td></tr>
<tr><td>UPFRONT (RPT)<p>9:00am - 9:30am</p></td><td>PM EXPRESS(RPT)<p>9:00am - 9:30am</p></td><td>PM EXPRESS(RPT)<p>9:00am - 9:30am</p></td><td>PM EXPRESS(RPT)<p>9:00am - 9:30am</p></td><td>PM BUSINESS EDITION (RPT)<p>9:00am - 9:30am</p></td><td>NEWSFILE (Live)<p>9:00am - 9:30am</p></td><td>DW TV<p>9:00am - 9:30am</p></td></tr>
<tr><td>UPFRONT (RPT)<p>9:30am - 9:55am</p></td><td>PM EXPRESS(RPT)<p>9:30am - 9:55am</p></td><td>PM EXPRESS(RPT)<p>9:30am - 9:55am</p></td><td>PM EXPRESS(RPT)<p>9:30am - 9:55am</p></td><td>PM BUSINESS EDITION (RPT)<p>9:30am - 9:55am</p></td><td>NEWSFILE (Live)<p>9:30am - 9:55am</p></td><td>JN EXTRA<p>9:30am - 9:55am</p></td></tr>
<tr><td>P &amp; P<p>9:55am -10:00am</p></td><td>FIGHTING DEATH<p>9:55am -10:00am</p></td><td>P &amp; P<p>9:55am -10:00am</p></td><td>DODOMA EDUCATION<p>9:55am -10:00am</p></td><td>DODOMA ROAD<p>9:55am -10:00am</p>&gt;</td><td>NEWSFILE (Live)<p>9:55am -10:00am</p></td><td>JN EXTRA<p>9:55am -10:00am</p></td></tr>
<tr><td>NEWS DESK (Live)<p>10:00am - 11:00am</p></td><td>NEWS DESK (Live)<p>10:00am - 11:00am</p></td><td>NEWS DESK (Live)<p>10:00am - 11:00am</p></td><td>NEWS DESK (Live)<p>10:00am - 11:00am</p></td><td>NEWS DESK (Live)<p>10:00am - 11:00am</p></td><td>NEWSFILE (Live)<p>10:00am - 11:00am</p></td><td>JN EXTRA<p>10:00am - 11:00am</p></td></tr>
<tr><td>JN INTERACTIVE(Live)<p>11:00am - 11:30am</p></td><td>JN INTERACTIVE(Live)<p>11:00am - 11:30am</p></td><td>JN INTERACTIVE(Live)<p>11:00am - 11:30am</p></td><td>JN INTERACTIVE(Live)<p>11:00am - 11:30am</p></td><td>JN INTERACTIVE(Live)<p>11:00am - 11:30am</p></td><td>NEWSFILE(Live)<p>11:00am - 11:30am</p></td><td>JN EXTRA<p>11:00am - 11:30am</p></td></tr>
<tr><td>DW ECO AFRICA<p>11:30am - 11:55am</p></td><td>DW IN GOOD SHAPE<p>11:30am - 11:55am</p></td><td>DW EUROMAXX<p>11:30am - 11:55am</p></td><td>DW THE 77 PERCENT<p>11:30am - 11:55am</p></td><td>DW DRIVE IT<p>11:30am - 11:55am</p></td><td>NEWSFILE(Live)<p>11:30am - 11:55am</p></td><td>HOTLINE - GULF IN THE CITY<p>11:30am - 11:55am</p></td></tr>
<tr><td>UNSCRIPTED<p>11:55am - 12:00am</p></td><td>UNSCRIPTED<p>11:55am - 12:00am</p></td><td>UNSCRIPTED<p>11:55am - 12:00am</p></td><td>UNSCRIPTED<p>11:55am - 12:00am</p></td><td>UNSCRIPTED<p>11:55am - 12:00am</p></td><td>NEWSFILE(Live)<p>11:55am - 12:00am</p></td><td>HOTLINE - GULF IN THE CITY<p>11:55am - 12:00am</p></td></tr>
<tr><td>NEWS TODAY (Live)<p>12:00am - 1:00pm</p></td><td>NEWS TODAY (Live)<p>12:00am - 1:00pm</p></td><td>NEWS TODAY (Live)<p>12:00am - 1:00pm</p></td><td>NEWS TODAY (Live)<p>12:00am - 1:00pm</p></td><td>NEWS TODAY (Live)<p>12:00am - 1:00pm</p></td><td>JN EXTRA<p>12:00am - 1:00pm</p></td><td>JN EXTRA<p>12:00am - 1:00pm</p></td></tr>
<tr><td>THE MARKET PLACE (Live)<p>1:00pm - 1:30pm</p></td><td>THE MARKET PLACE (Live)<p>1:00pm - 1:30pm</p></td><td>THE MARKET PLACE (Live)<p>1:00pm - 1:30pm</p></td><td>THE MARKET PLACE (Live)<p>1:00pm - 1:30pm</p></td><td>THE MARKET PLACE (Live)<p>1:00pm - 1:30pm</p></td><td>WEEKEND 1<p>1:00pm - 1:30pm</p></td><td>WEEKEND 1<p>1:00pm - 1:30pm</p></td></tr>
<tr><td>JN EXTRA<p>1:30pm - 1:55pm</p></td><td>JN EXTRA<p>1:30pm - 1:55pm</p></td><td>JN EXTRA<p>1:30pm - 1:55pm</p></td><td>JN EXTRA<p>1:30pm - 1:55pm</p></td><td>JN EXTRA<p>1:30pm - 1:55pm</p></td><td>WEEKEND 1<p>1:30pm - 1:55pm</p></td><td>WEEKEND 1<p>1:30pm - 1:55pm</p></td></tr>
<tr><td>UNSCRIPTED<p>1:55pm - 2:00pm</p></td><td>UNSCRIPTED<p>1:55pm - 2:00pm</p></td><td>UNSCRIPTED<p>1:55pm - 2:00pm</p></td><td>UNSCRIPTED<p>1:55pm - 2:00pm</p></td><td>UNSCRIPTED<p>1:55pm - 2:00pm</p></td><td>WEEKEND 1<p>1:55pm - 2:00pm</p></td><td>WEEKEND 1<p>1:55pm - 2:00pm</p></td></tr>
<tr><td>SPORTS TODAY(Live)<p>2:00pm - 2:30pm</p></td><td>SPORTS TODAY(Live)<p>2:00pm - 2:30pm</p></td><td>SPORTS TODAY(Live)<p>2:00pm - 2:30pm</p></td><td>SPORTS TODAY(Live)<p>2:00pm - 2:30pm</p></td><td>SPORTS TODAY(Live)<p>2:00pm - 2:30pm</p></td><td>HOTLINE - GULF IN THE CITY<p>2:00pm - 2:30pm</p></td><td>PM PERSONALITY RPT<p>2:00pm - 2:30pm</p></td></tr>
<tr><td>PEOPLE &amp; PLACES<p>2:30pm - 2:55pm</p></td><td>COUNTING THE COST<p>2:30pm - 2:55pm</p></td><td>THE CURE<p>2:30pm - 2:55pm</p></td><td>AL JAZ-WITNESS<p>2:30pm - 2:55pm</p></td><td>AL JAZEERA WORLD<p>2:30pm - 2:55pm</p></td><td>JN EXTRA<p>2:30pm - 2:55pm</p></td><td>PM PERSONALITY RPT<p>2:30pm - 2:55pm</p></td></tr>
<tr><td>UNSCRIPTED / P&amp;P<p>2:55pm - 3:00pm</p></td><td>UNSCRIPTED / P&amp;P<p>2:55pm - 3:00pm</p></td><td>UNSCRIPTED / P&amp;P<p>2:55pm - 3:00pm</p></td><td>UNSCRIPTED / P&amp;P<p>2:55pm - 3:00pm</p></td><td>UNSCRIPTED / P&amp;P<p>2:55pm - 3:00pm</p></td><td>JN EXTRA<p>2:55pm - 3:00pm</p></td><td>PM PERSONALITY RPT<p>2:55pm - 3:00pm</p></td></tr>
<tr><td>THE PULSE (Live) + LTS<p>3:00pm - 4:00pm</p></td><td>THE PULSE (Live) + LTS<p>3:00pm - 4:00pm</p></td><td>THE PULSE (Live) + LTS<p>3:00pm - 4:00pm</p></td><td>THE PULSE (Live) + LTS<p>3:00pm - 4:00pm</p></td><td>THE PULSE (Live) + LTS<p>3:00pm - 4:00pm</p></td><td>UPFRONT RPT<p>3:00pm - 4:00pm</p></td><td>UPFRONT RPT<p>3:00pm - 4:00pm</p></td></tr>
<tr><td>THE PULSE (Live) + LTS<p>4:00pm - 4:30pm</p></td><td>THE PULSE (Live) + LTS<p>4:00pm - 4:30pm</p></td><td>THE PULSE (Live) + LTS<p>4:00pm - 4:30pm</p></td><td>THE PULSE (Live) + LTS<p>4:00pm - 4:30pm</p></td><td>THE PULSE (Live) + LTS<p>4:00pm - 4:30pm</p></td><td>NEWS GENERATION<p>4:00pm - 4:30pm</p></td><td>NEWS GENERATION<p>4:00pm - 4:30pm</p></td></tr>
<tr><td>THE PULSE (Live) + LTS<p>4:30pm - 5:00pm</p></td><td>THE PULSE (Live) + LTS<p>4:30pm - 5:00pm</p></td><td>THE PULSE (Live) + LTS<p>4:30pm - 5:00pm</p></td><td>THE PULSE (Live) + LTS<p>4:30pm - 5:00pm</p></td><td>THE PULSE (Live) + LTS<p>4:30pm - 5:00pm</p></td><td>JN EXTRA<p>4:30pm - 5:00pm</p></td><td>IN GOOD SHAPE<p>4:30pm - 5:00pm</p></td></tr>
<tr><td>BUSINESS LIVE<p>5:00pm - 5:30pm</p></td><td>BUSINESS LIVE<p>5:00pm - 5:30pm</p></td><td>BUSINESS LIVE<p>5:00pm - 5:30pm</p></td><td>BUSINESS LIVE<p>5:00pm - 5:30pm</p></td><td>BUSINESS LIVE<p>5:00pm - 5:30pm</p></td><td>HOTLINE - LEAKING WOUNDS<p>5:00pm - 5:30pm</p></td><td>EDITORS' FORUM (Live)<p>5:00pm - 5:30pm</p></td></tr>
<tr><td>BBC FOCUS ON AFRICA - NO STREAMING<p>5:30pm - 6:00pm</p></td><td>BBC FOCUS ON AFRICA - NO STREAMING<p>5:30pm - 6:00pm</p></td><td>BBC FOCUS ON AFRICA - NO STREAMING<p>5:30pm - 6:00pm</p></td><td>BBC FOCUS ON AFRICA - NO STREAMING<p>5:30pm - 6:00pm</p></td><td>BBC FOCUS ON AFRICA - NO STREAMING<p>5:30pm - 6:00pm</p></td><td>DW - REV<p>5:30pm - 6:00pm</p></td><td>EDITORS' FORUM (Live)<p>5:30pm - 6:00pm</p></td></tr>
<tr><td>GHANA POLICE WATCH<p>6:00pm - 6:30pm</p></td><td>JN EXTRA<p>6:00pm - 6:30pm</p></td><td>UPFRONT (Live)<p>6:00pm - 6:30pm</p></td><td>UPFRONT (Live)<p>6:00pm - 6:30pm</p></td><td>TOUCHLINE<p>6:00pm - 6:30pm</p></td><td>THE EXECUTIVE LOUNGE<p>6:00pm - 6:30pm</p></td><td>EDITORS' FORUM (Live)<p>6:00pm - 6:30pm</p></td></tr>
<tr><td>GHANA POLICE WATCH<p>6:30pm - 7:00pm</p></td><td>HOTLINE - GULF IN THE CITY<p>6:30pm - 7:00pm</p></td><td>UPFRONT (Live)<p>6:30pm - 7:00pm</p></td><td>UPFRONT (Live)<p>6:30pm - 7:00pm</p></td><td>MY COMMUNITY<p>6:30pm - 7:00pm</p></td><td>THE EXECUTIVE LOUNGE<p>6:30pm - 7:00pm</p></td><td>EDITORS' FORUM (Live)<p>6:30pm - 7:00pm</p></td></tr>
<tr><td>JOYNEWS PRIME (Live)<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME (Live)<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME (Live)<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME (Live)<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME (Live)<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME<p>7:00pm - 7:30pm</p></td><td>JOYNEWS PRIME<p>7:00pm - 7:30pm</p></td></tr>
<tr><td>JOYNEWS PRIME (Live)<p>7:30pm - 8:00pm</p></td><td>JOYNEWS PRIME (Live)<p>7:30pm - 8:00pm</p></td><td>JOYNEWS PRIME (Live)<p>7:30pm - 8:00pm</p></td><td>JOYNEWS PRIME (Live)<p>7:30pm - 8:00pm</p></td><td>JOYNEWS PRIME (Live)<p>7:30pm - 8:00pm</p></td><td>SCOREBOARD EP 124<p>7:30pm - 8:00pm</p></td><td>SCOREBORAD EP 125<p>7:30pm - 8:00pm</p></td></tr>
<tr><td>JOYNEWS PRIME (Live)<p>8:00pm - 8:30pm</p></td><td>JOYNEWS PRIME (Live)<p>8:00pm - 8:30pm</p></td><td>JOYNEWS PRIME (Live)<p>8:00pm - 8:30pm</p></td><td>JOYNEWS PRIME (Live)<p>8:00pm - 8:30pm</p></td><td>JOYNEWS PRIME (Live)<p>8:00pm - 8:30pm</p></td><td>HOTLINE - LEAKING WOUNDS<p>8:00pm - 8:30pm</p></td><td>HOTLINE - GULF IN TEH CITY<p>8:00pm - 8:30pm</p></td></tr>
<tr><td>HOTLINE - LEAKING WOUNDS<p>8:30pm - 9:00pm</p></td><td>JOYNEWS PRIME (Live)<p>8:30pm - 9:00pm</p></td><td>JOYNEWS PRIME (Live)<p>8:30pm - 9:00pm</p></td><td>JOYNEWS PRIME (Live)<p>8:30pm - 9:00pm</p></td><td>JOYNEWS PRIME (Live)<p>8:30pm - 9:00pm</p></td><td>JN EXTRA<p>8:30pm - 9:00pm</p></td><td>JN EXTRA<p>8:30pm - 9:00pm</p></td></tr>
<tr><td>PM EXPRESS (Live)<p>9:00pm - 9:30pm</p></td><td>PM EXPRESS (Live)<p>9:00pm - 9:30pm</p></td><td>PM EXPRESS (Live)<p>9:00pm - 9:30pm</p></td><td>PM BUSINESS EDITION<p>9:00pm - 9:30pm</p></td><td>PM EXPRESS - PERSONALITY<p>9:00pm - 9:30pm</p></td><td>JN EXTRA<p>9:00pm - 9:30pm</p></td><td>JN EXTRA<p>9:00pm - 9:30pm</p></td></tr>
<tr><td>PM EXPRESS (Live)<p>9:30pm - 10:00pm</p></td><td>PM EXPRESS (Live)<p>9:30pm - 10:00pm</p></td><td>PM EXPRESS (Live)<p>9:30pm - 10:00pm</p></td><td>PM BUSINESS EDITION<p>9:30pm - 10:00pm</p></td><td>PM EXPRESS - PERSONALITY<p>9:30pm - 10:00pm</p></td><td>NEWSFILE RPT<p>9:30pm - 10:00pm</p></td><td>THE EXECUTIVE LOUNGE<p>9:30pm - 10:00pm</p></td></tr>
<tr><td>JN EXTRA<p>10:00pm - 10:30pm</p></td><td>JN EXTRA<p>10:00pm - 10:30pm</p></td><td>JN EXTRA<p>10:00pm - 10:30pm</p></td><td>JN EXTRA<p>10:00pm - 10:30pm</p></td><td>JN EXTRA<p>10:00pm - 10:30pm</p></td><td>NEWSFILE RPT<p>10:00pm - 10:30pm</p></td><td>THE EXECUTIVE LOUNGE<p>10:00pm - 10:30pm</p></td></tr>
<tr><td>FOOTBALL SHOW (Live)<p>10:30pm - 11:00pm</p></td><td>FOOTBALL SHOW (Live)<p>10:30pm - 11:00pm</p></td><td>FOOTBALL SHOW (Live)<p>10:30pm - 11:00pm</p></td><td>FOOTBALL SHOW (Live)<p>10:30pm - 11:00pm</p></td><td>FOOTBALL SHOW (Live)<p>10:30pm - 11:00pm</p></td><td>NEWSFILE RPT<p>10:30pm - 11:00pm</p></td><td>DW TV<p>10:30pm - 11:00pm</p></td></tr>
<tr><td>NIGHTLY NEWS(Live) / JN EXTRA<p>11:00pm - 11:30pm</p></td><td>NIGHTLY NEWS(Live) / JN EXTRA<p>11:00pm - 11:30pm</p></td><td>NIGHTLY NEWS(Live) / JN EXTRA<p>11:00pm - 11:30pm</p></td><td>NIGHTLY NEWS(Live) / JN EXTRA<p>11:00pm - 11:30pm</p></td><td>NIGHTLY NEWS(Live) / JN EXTRA<p>11:00pm - 11:30pm</p></td><td>NEWSFILE RPT<p>11:00pm - 11:30pm</p></td><td>DW TV<p>11:00pm - 11:30pm</p></td></tr>
<tr><td>BUSINESS LIVE RPT<p>11:30pm - 12:00am</p></td><td>BUSINESS LIVE RPT<p>11:30pm - 12:00am</p></td><td>BUSINESS LIVE RPT<p>11:30pm - 12:00am</p></td><td>BUSINESS LIVE RPT<p>11:30pm - 12:00am</p></td><td>BUSINESS LIVE RPT<p>11:30pm - 12:00am</p></td><td>NEWSFILE RPT<p>11:30pm - 12:00am</p></td><td>DW TV<p>11:30pm - 12:00am</p></td></tr>
<tr><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td><td>DW TV<p>12:00am - 5:50am</p></td></tr>
</tbody></table></div>
                

                <div className="programs-lineup table-responsive programs-window" style={channel === "ad" ? {display: "block"}: {display: "none"}}>
<h1>AdomTv Programs</h1>
<table className="table">

<tbody>
<tr><th>Monday</th><th>Tuesday</th><th>Wednesday</th><th>Thursday</th><th>Friday</th><th>Saturday</th><th>Sunday</th></tr>
<tr><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>12:00am - 12:30am</p></td><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td><td>RELIGIOUS BROADCAST<p>12:00am - 12:30am</p></td></tr>
<tr><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td><td>RELIGIOUS BROADCAST<p>12:30am - 1:00am</p></td></tr>
<tr><td>AGORO NE FOM<p>1:00am - 1:30am</p></td><td>RELIGIOUS BROADCAST<p>1:00am - 1:30am</p></td><td>AWARESEM RPT<p>1:00am - 1:30am</p></td><td>NKWA HIA RPT<p>1:00am - 1:30am</p></td><td>GUEST ROO 1, 2<p>1:00am - 1:30am</p></td><td>THE TRAP 1, 2<p>1:00am - 1:30am</p></td><td>OKOMFUO ASIMANIWAA 3, 4<p>1:00am - 1:30am</p></td></tr>
<tr><td>AGORO NE FOM<p>1:30am - 2:00am</p></td><td>MENU MEHU 3, 4<p>1:30am - 2:00am</p></td><td>AWARESEM RPT<p>1:30am - 2:00am</p></td><td>NKWA HIA RPT<p>1:30am - 2:00am</p></td><td>GUEST ROOM 1, 2<p>1:30am - 2:00am</p></td><td>THE TRAP 1, 2<p>1:30am - 2:00am</p></td><td>OKOMFUO ASIMANIWAA 3, 4<p>1:30am - 2:00am</p></td></tr>
<tr><td>MENU MEHU 1, 2<p>2:00am - 4:00am</p></td><td>MENU MEHU 3, 4<p>2:00am - 4:00am</p></td><td>ASAASE SO BONSAM 1, 2<p>2:00am - 4:00am</p></td><td>ASAASE SO BONSAM 3, 4<p>2:00am - 4:00am</p></td><td>GUEST ROOM 1, 2<p>2:00am - 4:00am</p></td><td>THE TRAP 1, 2<p>2:00am - 4:00am</p></td><td>OKOMFUO ASIMANIWAA 3, 4<p>2:00am - 4:00am</p></td></tr>
<tr><td>MENU MEHU 1, 2<p>4:00am - 4:30am</p></td><td>MENU MEHU 3, 4<p>4:00am - 4:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:00am - 4:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:00am - 4:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:00am - 4:30am</p></td><td>THE TRAP 1, 2<p>4:00am - 4:30am</p></td><td>OKOMFUO ASIMANIWAA 3, 4<p>4:00am - 4:30am</p></td></tr>
<tr><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td><td>BREAKING YOKE MIN<p>4:30am - 5:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>4:30am - 5:00am</p></td></tr>
<tr><td>GOSPEL MUSIC COMPILATION<p>5:00am - 5:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>5:00am - 5:30am</p></td><td>BOSUO<p>5:00am - 5:30am</p></td><td>BREAKING YOKE MIN<p>5:00am - 5:30am</p></td><td>BOSUO<p>5:00am - 5:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>5:00am - 5:30am</p></td><td>GOSPEL MUSIC COMPILATION<p>5:00am - 5:30am</p></td></tr>
<tr><td>BADWAM<p>5:30am - 6:00am</p></td><td>BADWAM<p>5:30am - 6:00am</p></td><td>BADWAM<p>5:30am - 6:00am</p></td><td>BADWAM<p>5:30am - 6:00am</p></td><td>BADWAM<p>5:30am - 6:00am</p></td><td>CHRISTIAN DIVINE CH<p>5:30am - 6:00am</p></td><td>APOSTOLIC PRAYER MIN<p>5:30am - 6:00am</p></td></tr>
<tr><td>BADWAM<p>6:00am - 6:30am</p></td><td>BADWAM<p>6:00am - 6:30am</p></td><td>BADWAM<p>6:00am - 6:30am</p></td><td>BADWAM<p>6:00am - 6:30am</p></td><td>BADWAM<p>6:00am - 6:30am</p></td><td>DR. MENSAH<p>6:00am - 6:30am</p></td><td>GLOBAL REVIVAL CH<p>6:00am - 6:30am</p></td></tr>
<tr><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>HEALTH SOLUTION<p>6:30am - 7:00am</p></td><td>CHURCH OF CHRIST T'ADI<p>6:30am - 7:00am</p></td></tr>
<tr><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>BADWAM<p>6:30am - 7:00am</p></td><td>HEALTH SOLUTION<p>6:30am - 7:00am</p></td><td>CHURCH OF CHRIST T'ADI<p>6:30am - 7:00am</p></td></tr>
<tr><td>BADWAM<p>7:00am - 7:30am</p></td><td>BADWAM<p>7:00am - 7:30am</p></td><td>BADWAM<p>7:00am - 7:30am</p></td><td>BADWAM<p>7:00am - 7:30am</p></td><td>BADWAM<p>7:00am - 7:30am</p></td><td>LIVING TESTIMONY<p>7:00am - 7:30am</p></td><td>ELKANA MINISTRY<p>7:00am - 7:30am</p></td></tr>
<tr><td>BADWAM<p>7:30am - 8:00am</p></td><td>BADWAM<p>7:30am - 8:00am</p></td><td>BADWAM<p>7:30am - 8:00am</p></td><td>BADWAM<p>7:30am - 8:00am</p></td><td>BADWAM<p>7:30am - 8:00am</p></td><td>CHRIST EMBASSY<p>7:30am - 8:00am</p></td><td>CHUURCH OF CHRIST ATIEKU<p>7:30am - 8:00am</p></td></tr>
<tr><td>BADWAM<p>8:00am - 9:00am</p></td><td>BADWAM<p>8:00am - 9:00am</p></td><td>BADWAM<p>8:00am - 9:00am</p></td><td>BADWAM<p>8:00am - 9:00am</p></td><td>BADWAM<p>8:00am - 9:00am</p></td><td>NNAWOTWE YI - LIVE<p>8:00am - 9:00am</p></td><td>ICGC LIVE<p>8:00am - 9:00am</p></td></tr>
<tr><td>SARAS REPEAT<p>9:00am - 10:00am</p></td><td>SARAS REPEAT<p>9:00am - 10:00am</p></td><td>SARAS REPEAT<p>9:00am - 10:00am</p></td><td>SARAS REPEAT<p>9:00am - 10:00am</p></td><td>SARAS REPEAT<p>9:00am - 10:00am</p></td><td>NNAWOTWE YI - LIVE<p>9:00am - 10:00am</p></td><td>DWAPA<p>9:00am - 10:00am</p></td></tr>
<tr><td>MANI DASOO ASA 1<p>10:00am - 10:30am</p></td><td>PAMPASO<p>10:00am - 10:30am</p></td><td>HEART IT FROM CHINA<p>10:00am - 10:30am</p></td><td>FABEWOSO<p>10:00am - 10:30am</p></td><td>MANI DASOO ASA 4<p>10:00am - 10:30am</p></td><td>MMOFRA MMERE<p>10:00am - 10:30am</p></td><td>CIRCLE ABRABO<p>10:00am - 10:30am</p></td></tr>
<tr><td>MANI DASOO ASA 1<p>10:30am - 11:00am</p></td><td>PAMPASO<p>10:30am - 11:00am</p></td><td>MANI DASOO ASA 2<p>10:30am - 11:00am</p></td><td>MANI DASOO ASA 3<p>10:30am - 11:00am</p></td><td>MANI DASOO ASA 4<p>10:30am - 11:00am</p></td><td>RONALDO<p>10:30am - 11:00am</p></td><td>PANIE NWO PANIE<p>10:30am - 11:00am</p></td></tr>
<tr><td>MANI DASOO ASA 1<p>11:00am - 11:30am</p></td><td>WONKYI NE SEN<p>11:00am - 11:30am</p></td><td>MANI DASOO ASA 2<p>11:00am - 11:30am</p></td><td>MANI DASOO ASA 3<p>11:00am - 11:30am</p></td><td>MANI DASOO ASA 4<p>11:00am - 11:30am</p></td><td>AGORO NE FOM LIVE<p>11:00am - 11:30am</p></td><td>TUMI WURA DOC &amp; KASAPREKO<p>11:00am - 11:30am</p></td></tr>
<tr><td>MANI DASOO ASA 1<p>11:30am - 12:00pm</p></td><td>HEART IT FROM CHINA<p>11:30am - 12:00pm</p></td><td>MANI DASOO ASA 2<p>11:30am - 12:00pm</p></td><td>MANI DASOO ASA 3<p>11:30am - 12:00pm</p></td><td>CHAMPION DIVINE<p>11:30am - 12:00pm</p></td><td>AGORO NE FOM LIVE<p>11:30am - 12:00pm</p></td><td>ABUSUA MEFRI MU 3 &amp; GIFAS DOC<p>11:30am - 12:00pm</p></td></tr>
<tr><td>PREMTOBRE - LIVE<p>12:00pm - 12:30pm</p></td><td>PREMTOBRE - LIVE<p>12:00pm - 12:30pm</p></td><td>PREMTOBRE - LIVE<p>12:00pm - 12:30pm</p></td><td>PREMTOBRE - LIVE<p>12:00pm - 12:30pm</p></td><td>PREMTOBRE - LIVE<p>12:00pm - 12:30pm</p></td><td>PREMTOBRE KASEE - LIVE<p>12:00pm - 12:30pm</p></td><td>ABUSUA MEFRI MU 3 &amp; GIFAS DOC<p>12:00pm - 12:30pm</p></td></tr>
<tr><td>GOSPEL MUSIC COMPILATION<p>1:00pm - 1:30pm</p></td><td>FIRE FOR FIRE - LIVE<p>1:00pm - 1:30pm</p></td><td>FIRE FOR FIRE - LIVE<p>1:00pm - 1:30pm</p></td><td>FIRE FOR FIRE - LIVE<p>1:00pm - 1:30pm</p></td><td>FIRE FOR FIRE - LIVE<p>1:00pm - 1:30pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>1:00pm - 1:30pm</p></td><td>JIJI MAA OMINIBUS (JAGGERY &amp; ENDPOINT)<p>1:00pm - 1:30pm</p></td></tr>
<tr><td>IN LOVE WITH RAMON 102<p>1:30pm - 2:30pm</p></td><td>IN LOVE WITH RAMON 104<p>1:30pm - 2:30pm</p></td><td>IN LOVE WITH RAMON 103<p>1:30pm - 2:30pm</p></td><td>IN LOVE WITH RAMON 105<p>1:30pm - 2:30pm</p></td><td>IN LOVE WITH RAMON 106<p>1:30pm - 2:30pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>1:30pm - 2:30pm</p></td><td>JIJI MAA OMINIBUS (JAGGERY &amp; ENDPOINT)<p>1:30pm - 2:30pm</p></td></tr>
<tr><td>AHINTASEM EP.49<p>2:30pm - 3:30pm</p></td><td>AHINTASEM EP.50<p>2:30pm - 3:30pm</p></td><td>AHINTASEM EP.51<p>2:30pm - 3:30pm</p></td><td>AHINTASEM EP.52<p>2:30pm - 3:30pm</p></td><td>ADOM GOSPEL ROCKSHOW RPT<p>2:30pm - 3:30pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>2:30pm - 3:30pm</p></td><td>JIJI MAA OMINIBUS (JAGGERY &amp; ENDPOINT)<p>2:30pm - 3:30pm</p></td></tr>
<tr><td>AHINTASEM CHATROOM<p>3:30pm - 4:00pm</p></td><td>AHINTASEM CHATROOM<p>3:30pm - 4:00pm</p></td><td>AHINTASEM CHATROOM<p>3:30pm - 4:00pm</p></td><td>AHINTASEM CHATROOM<p>3:30pm - 4:00pm</p></td><td>SOUNDCHECK<p>3:30pm - 4:00pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>3:30pm - 4:00pm</p></td><td>JIJI MAA OMINIBUS (JAGGERY &amp; ENDPOINT)<p>3:30pm - 4:00pm</p></td></tr>
<tr><td>SAPNE EP.510 &amp; 511<p>4:00pm - 4:30pm</p></td><td>SAPNE EP.512 &amp; 513<p>4:00pm - 4:30pm</p></td><td>SAPNE EP.514 &amp; 515<p>4:00pm - 4:30pm</p></td><td>SAPNE EP.516 &amp; 517<p>4:00pm - 4:30pm</p></td><td>SAPNE EP.518 &amp; 519<p>4:00pm - 4:30pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>4:00pm - 4:30pm</p></td><td>JIJI MAA OMINIBUS (JAGGERY &amp; ENDPOINT)<p>4:00pm - 4:30pm</p></td></tr>
<tr><td>SAPNE EP.510 &amp; 511<p>4:30pm - 5:00pm</p></td><td>SAPNE EP.512 &amp; 513<p>4:30pm - 5:00pm</p></td><td>SAPNE EP.514 &amp; 515<p>4:30pm - 5:00pm</p></td><td>SAPNE EP.516 &amp; 517<p>4:30pm - 5:00pm</p></td><td>SAPNE EP.518 &amp; 519<p>4:30pm - 5:00pm</p></td><td>SAPNE OMNIBUS &amp; (END POINT DOC)<p>4:30pm - 5:00pm</p></td><td>GREETINGS FROM ABROAD<p>4:30pm - 5:00pm</p></td></tr>
<tr><td>SAPNE CHATROOM<p>5:00pm - 5:30pm</p></td><td>SAPNE CHATROOM<p>5:00pm - 5:30pm</p></td><td>SAPNE CHATROOM<p>5:00pm - 5:30pm</p></td><td>SAPNE CHATROOM<p>5:00pm - 5:30pm</p></td><td>SAPNE CHATROOM<p>5:00pm - 5:30pm</p></td><td>ABEN SHOW<p>5:00pm - 5:30pm</p></td><td>MY LIFE 2<p>5:00pm - 5:30pm</p></td></tr>
<tr><td>THE BIG AGENDA &amp; DBS DOC<p>5:30pm - 6:00pm</p></td><td>THE BIG AGENDA<p>5:30pm - 6:00pm</p></td><td>THE BIG AGENDA &amp; DBS DOC<p>5:30pm - 6:00pm</p></td><td>THE BIG AGENDA<p>5:30pm - 6:00pm</p></td><td>THE BIG AGENDA &amp; DBS DOC<p>5:30pm - 6:00pm</p></td><td>ABEN SHOW<p>5:30pm - 6:00pm</p></td><td>MY LIFE 2<p>5:30pm - 6:00pm</p></td></tr>
<tr><td>BISA NNWOM - LIVE<p>6:00pm - 6:30pm</p></td><td>BISA NNWOM - LIVE<p>6:00pm - 6:30pm</p></td><td>BISA NNWOM - LIVE<p>6:00pm - 6:30pm</p></td><td>BISA NNWOM - LIVE<p>6:00pm - 6:30pm</p></td><td>BISA NNWOM - LIVE<p>6:00pm - 6:30pm</p></td><td>OKUKUSEKU THE TALK SHOW<p>6:00pm - 6:30pm</p></td><td>MY LIFE 2<p>6:00pm - 6:30pm</p></td></tr>
<tr><td>ADOM KASEE - LIVE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE - LIVE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE - LIVE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE - LIVE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE - LIVE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE<p>6:30pm - 7:00pm</p></td><td>ADOM KASEE<p>6:30pm - 7:00pm</p></td></tr>
<tr><td>ADOM KASEE - LIVE<p>7:00pm - 7:30pm</p></td><td>ADOM KASEE - LIVE<p>7:00pm - 7:30pm</p></td><td>ADOM KASEE - LIVE<p>7:00pm - 7:30pm</p></td><td>ADOM KASEE - LIVE<p>7:00pm - 7:30pm</p></td><td>ADOM KASEE - LIVE<p>7:00pm - 7:30pm</p></td><td>NKWA HIA<p>7:00pm - 7:30pm</p></td><td>ADOM KASEE<p>7:00pm - 7:30pm</p></td></tr>
<tr><td>JIJI MAA EP. 91 &amp; 92<p>7:30pm - 8:00pm</p></td><td>JIJI MAA EP. 93 &amp; 94<p>7:30pm - 8:00pm</p></td><td>JIJI MAA EP. 95 &amp; 96<p>7:30pm - 8:00pm</p></td><td>JIJI MAA EP. 97 &amp; 98<p>7:30pm - 8:00pm</p></td><td>JIJI MAA EP. 99 &amp; 100<p>7:30pm - 8:00pm</p></td><td>NKWA HIA<p>7:30pm - 8:00pm</p></td><td>COVID 19 - MATTERS ARISING (IVE SHOW)<p>7:30pm - 8:00pm</p></td></tr>
<tr><td>JIJI MAA EP. 91 &amp; 92<p>8:00pm - 8:30pm</p></td><td>JIJI MAA EP. 93 &amp; 94<p>8:00pm - 8:30pm</p></td><td>JIJI MAA EP. 95 &amp; 96<p>8:00pm - 8:30pm</p></td><td>JIJI MAA EP. 97 &amp; 98<p>8:00pm - 8:30pm</p></td><td>JIJI MAA EP. 99 &amp; 100<p>8:00pm - 8:30pm</p></td><td>VIM TALK<p>8:00pm - 8:30pm</p></td><td>COVID 19 - MATTERS ARISING (IVE SHOW)<p>8:00pm - 8:30pm</p></td></tr>
<tr><td>JIJI MAA EP. 91 &amp; 92<p>8:30pm - 9:00pm</p></td><td>JIJI MAA EP. 93 &amp; 94<p>8:30pm - 9:00pm</p></td><td>JIJI MAA EP. 95 &amp; 96<p>8:30pm - 9:00pm</p></td><td>JIJI MAA EP. 97 &amp; 98<p>8:30pm - 9:00pm</p></td><td>JIJI MAA EP. 99 &amp; 100<p>8:30pm - 9:00pm</p></td><td>MY LIFE 1<p>8:30pm - 9:00pm</p></td><td>COVID 19 - MATTERS ARISING (IVE SHOW)<p>8:30pm - 9:00pm</p></td></tr>
<tr><td>AHINTASEM REPEAT<p>9:00pm - 9:30pm</p></td><td>AHINTASEM REPEAT<p>9:00pm - 9:30pm</p></td><td>AHINTASEM REPEAT<p>9:00pm - 9:30pm</p></td><td>AHINTASEM REPEAT<p>9:00pm - 9:30pm</p></td><td>JIJI MAA CHATROOM<p>9:00pm - 9:30pm</p></td><td>MY LIFE 1<p>9:00pm - 9:30pm</p></td><td>AFTER HOURS<p>9:00pm - 9:30pm</p></td></tr>
<tr><td>AHINTASEM REPEAT<p>9:30pm - 10:00pm</p></td><td>AHINTASEM REPEAT<p>9:30pm - 10:00pm</p></td><td>AHINTASEM REPEAT<p>9:30pm - 10:00pm</p></td><td>AHINTASEM REPEAT<p>9:30pm - 10:00pm</p></td><td>WARE HWE<p>9:30pm - 10:00pm</p></td><td>SIKA KORABEA<p>9:30pm - 10:00pm</p></td><td>AFTER HOURS<p>9:30pm - 10:00pm</p></td></tr>
<tr><td>AWARESEM<p>10:00pm - 10:30pm</p></td><td>ADOM TROTRO<p>10:00pm - 10:30pm</p></td><td>OSEIKROM BURGER<p>10:00pm - 10:30pm</p></td><td>AHOTO FIE<p>10:00pm - 10:30pm</p></td><td>POWER CLINIC<p>10:00pm - 10:30pm</p></td><td>NEW FOUNTAIN<p>10:00pm - 10:30pm</p></td><td>HABIBI HERBAL<p>10:00pm - 10:30pm</p></td></tr>
<tr><td>AWARESEM<p>10:30pm - 11:00pm</p></td><td>SOUNDCHECK<p>10:30pm - 11:00pm</p></td><td>GOSPEL MUSIC COMPILATION<p>10:30pm - 11:00pm</p></td><td>LET'S TALK ABILITY<p>10:30pm - 11:00pm</p></td><td>ABUSUA MEFRI MU 2 CLINIC<p>10:30pm - 11:00pm</p></td><td>ASUMASEM<p>10:30pm - 11:00pm</p></td><td>FAKYE ME<p>10:30pm - 11:00pm</p></td></tr>
<tr><td>FABEWOSO<p>11:00pm - 11:30pm</p></td><td>ABUSUA MEFRI MU 1<p>11:00pm - 11:30pm</p></td><td>AMMAMERE TV<p>11:00pm - 11:30pm</p></td><td>LET'S TALK ABILITY<p>11:00pm - 11:30pm</p></td><td>ABUSUA MEFRI MU 2 CLINIC<p>11:00pm - 11:30pm</p></td><td>ASUMASEM<p>11:00pm - 11:30pm</p></td><td>AMMAMERE TV<p>11:00pm - 11:30pm</p></td></tr>
<tr><td>GOSPEL MUSIC COMPILATION<p>11:30pm - 12:00am</p></td><td>ABUSUA MEFRI MU 1<p>11:30pm - 12:00am</p></td><td>AMMAMERE TV<p>11:30pm - 12:00am</p></td><td>GOSPEL MUSIC COMPILATION<p>11:30pm - 12:00am</p></td><td>ABUSUA MEFRI MU 2 CLINIC<p>11:30pm - 12:00am</p></td><td>MULTI TV DOC<p>11:30pm - 12:00am</p></td><td>AMMAMERE TV<p>11:30pm - 12:00am</p></td></tr>
</tbody></table></div>
            
        </div>
       
    )
}

export default LiveTv;