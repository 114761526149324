//import { useQuery,useLazyQuery } from '@apollo/client'
import { useState } from 'react'
//import { LOAD_RELATED_STORIES, LOAD_RELATED } from '../graphql/queries.js'
import StoryItem from './storyitems.js';
import useRelatedStoriesList from './relatedstorieslist.js'
//import { oilTemp } from 'fontawesome';
//import Ads from './ads.js';
//import FeedItem from './feeditem.js';
//import GoogleAds from './googleads.js';
import Customad from './customad.js';
import FeedItem from './feeditem.js';




export default function RelatedStories(props){
   
    //const [storyitem, setStoryItem] = useState([])

   
    const { newsitems, error, loading } = useRelatedStoriesList(props.tags);

    
   
    const strg = (itemarray) =>{
            let tag =""
        for(let i=0; i< itemarray.length; i++){
            if(i < itemarray.length-1){

                tag += itemarray[i].name +",";
            }else{
                tag += itemarray[i].name
            }
        }
     return tag
     }


     const time_ago = (datepublished) =>{
        // var datepublished = datepublished.split("T");
         //console.log(datepublished[0])
         var datepub = new Date(datepublished)
         var datecurrent = new Date()
         datecurrent = datecurrent.getTime()
 
         var datedif = datecurrent - (datepub.getTime());
 
         var tm = Math.floor(datedif / 60000);

         if(Math.floor(tm/(43200*12)) > 0){
 
            return Math.floor(tm/(43200*12)) + " years ago";

        }else if (Math.floor(tm/(1440*30)) > 0){
 
            return Math.floor(tm/(1440*30)) + " months ago";
        }else if(Math.floor(tm/(1440*7)) > 0){
 
            return (Math.floor(tm/(1440*7))) + " weeks ago";
        }else if(Math.floor(tm / 1440) > 0){
 
            return (Math.floor(tm/1440)) + " day ago"
        }else if(Math.floor(tm) > 60){
            let hrago = Math.floor (Math.floor(tm) / 60);
            let minago = Math.floor(tm - (hrago * 60))
            if(hrago > 24 ){

                return (Math.floor(hrago /24)) + " days ago"
            }
            return hrago + "hr " + minago + " min ago"

        }else if(Math.floor(tm) < 60){
 
            return Math.floor(tm) + " min ago"

        }else if(tm <= 0 ){
 
             return "Just Now"
 
         }
 
        // return Math.floor(datedif / 60000) / 60 
     }

     const stringify = (dgarray) =>{
        let ttg  = "";
        for(let i=0; i < dgarray.length; i++){

            if(i < dgarray.length-1){

                ttg+= dgarray[i].name+",";
            }else{
                ttg+= dgarray[i].name
            }   
        }
        return ttg;
    }

    const catObject= (dgarray) =>{
        let ttg  = [];
        for(let i=0; i < dgarray.length; i++){

            
                if(dgarray[i].parent != null){
                    
                    ttg[i] = {

                        "catname" : dgarray[i].name,
                        "catpath" : dgarray[i].parent.node.name +"/" + dgarray[i].name,
                    }
                    //ttg+= dgarray[i].parent.node.name +"/" + dgarray[i].name+",";

                }else{
                    ttg[i] = {

                        "catname" : dgarray[i].name,
                        "catpath" : dgarray[i].name,
                    }
                    //ttg+= dgarray[i].name+",";

                }
                  
        }
        return ttg;
    }



     if(error)
     return <div>Error</div>

     if(loading)
     return <div>Loading...</div>
    return(
        
        <div style={{width:"100%", float:"left"}}>
       
        {
        
        
            
        newsitems.length > 0 &&
        newsitems.map((item, index) => {
          
            if(props.tagged){


                return(

                    <> 
                     <FeedItem  key={item.id} id={item.id} title={item.title} datepublished={time_ago(item.dateGmt)} image={item.featuredImage.node.sourceUrl} storyurl={"../"+item.slug+"/"} tags={stringify(item.tags.nodes)} summary={item.excerpt} category={ catObject(item.categories.nodes)} />     
                   {/* <StoryItem itemref={item.id} style={{display:"none"}} className="relatedmarg" key={index} title={item.title} datepublished={item.dateGmt} content={item.content} storyimage={item.featuredImage.node.sourceUrl} tags={strg(item.tags.nodes)} storyid={item.id} source={item.source} summary={item.excerpt} storyurl={"https://www.myjoyonline.com/"+item.slug}/>*/}
                    <Customad id={item.id} slot="/1002554/Myjoyonline/myjoyonline_home_feed_native" />
                       </>    
                       )
            }else if(props.slugid !== item.slug){
               
         return(

            <> 
            <FeedItem  key={item.id} id={item.id} title={item.title} datepublished={time_ago(item.dateGmt)} image={item.featuredImage.node.sourceUrl} storyurl={item.slug+"/"} tags={stringify(item.tags.nodes)} summary={item.excerpt} category={ catObject(item.categories.nodes)} />  
                  {/*<StoryItem itemref={item.id} style={{display: "none"}} className="relatedmarg" key={index} title={item.title} datepublished={item.dateGmt} content={item.content} storyimage={item.featuredImage.node.sourceUrl} tags={strg(item.tags.nodes)} storyid={item.id} source={item.source} summary={item.excerpt} storyurl={"https://www.myjoyonline.com/"+item.slug} />*/}
            <Customad id={item.id} slot="/1002554/Myjoyonline/myjoyonline_home_feed_native" />
            

</>
               )
         }
            
            }
            )      
       
    }
        </div>
        
    )

}