import Discover from './discover.js';
import Trends from './trending.js';
import Ads from './ads.js';
import Forex from './forex.js';
import { useLocation, Location } from 'react-router-dom';
import GoogleAds from './googleads.js';
import { Link } from "react-router-dom"
import { useState, useRef, useCallback, useEffect } from 'react';
import Icon from '@mdi/react';
import {mdiTwitter, mdiWhatsapp, mdiInstagram, mdiYoutube, mdiLinkedin } from '@mdi/js';
import LiveStream from './homelivestream.js';




function SideBar(props,{match}){
const windowpath = window.location.pathname;
const [showhide, setShowHide] = useState("block")
const [scrollposition, setScrollPosition] = useState(0)
const [windowscroll,setWindowscroll] = useState(window.pageYOffset)
const [fposition,setFposition] = useState("relative")


    var fxp = "";
    var fx = "";
    const checkstatic = () =>{
      if(scrollposition < windowscroll ){
       // console.log(scrollposition)
       // setScrollPosition(windowscroll)
        setFposition("relative")
      }else{

        //setFposition("fixed")
        //setScrollPosition(windowscroll)
        //console.log(scrollposition)
      }
          
          //console.log(windowscroll)

    }

   /* const spp = document.getElementById("copyrightl")
    const [scrollPosition, setScrollPosition] = useState();
    
    useEffect(()=>{
     // setScrollPosition(spp.getBoundingClientRect().y)

    },[])*/

    const handleScroll = () => {
      
      //const position = window.pageYOffset;
      //console.log(windowscroll) 
      //setWindowscroll(position);
     // setScrollPosition(ycpr); 
     setFposition(checkVisible(document.getElementById('fxwidget'))?"fixed":"relative")
     
    };
  

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
     // checkstatic()
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
      //console.log(scrollPosition)
    }, [windowscroll, fx]);


    const checkVisible = (elm) => {
      var rect = elm.getBoundingClientRect();
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
     // console.log(rect.top +"---"+viewHeight)
      return !(rect.top - viewHeight >= -(viewHeight + 415));
    }



    useEffect(()=>{

      if(windowpath === "/"){

        setShowHide("block")
      }else{setShowHide("none")}

    },[windowpath])

    const observer = useRef();
    var items_more = 20;
    const sidebarRef = useCallback(node => {

       
        if(observer.current)observer.current.disconnect()

        observer.current = new IntersectionObserver(entries =>{

            if(entries[0].isIntersecting){

              //checkstatic()

              //setScrollPosition(window.pageYOffset)
                //setPage(prevPage => prevPage + 10)

                //setFposition("fixed")
                //console.log("in view" + scrollposition)
               
                        
            
            }
        })

        if(node) observer.current.observe(node)
    },[])
   
    return(
     
    <div className="hide-mobile" style={{top:"54px", maxWidth:"300px", position:"relative"}}>
      
       <GoogleAds slot={"/1002554/Myjoyonline/myjoyonline_home_sidebar_secondary"} id={"div-gpt-ad-1645435686204-0"}/>
        
       
        <div style={{marginBottom: "20px"}}> 
            
        <Trends />
        </div> 
        <Discover />
        <div id="fxwidget">
        <Forex />
        
        </div>
        <div style={{position:"relative"}}>
        <div id="fixed-ad" style={{position:fposition, top:"0px", transition: "all .4s"}}>


          {
           <div style={{display:showhide}}>
            <LiveStream />
            </div>
            
            }
        <GoogleAds slot={"/1002554/Myjoyonline/myjoyonline_home_sidebar_main"} id={"div-gpt-ad-1645435611300-0"}/> 
        <div className="stayconnected" style={{borderRadius:"20px", border: "1px dashed #ccc", margin: "25px auto 10px", padding: "10px 20px"}}>
        <h6>Stay Connected</h6>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <Link to={{pathname:"https://www.youtube.com/user/myjoyonlinetube?sub_confirmation=1"}} className="youtube"><Icon path={mdiYoutube} title="Youtube" size={1} horizontal vertical rotate={180} color="#1da1f2" /></Link>
        <Link to={{pathname:"https://web.facebook.com/JoyNewsOnTV/"}} className="facebook"><i className="fa fa-facebook" aria-hidden="true" title="Facebook"></i></Link> 
        <Link to={{pathname:"https://twitter.com/Joy997FM"}} className="twitter"><Icon path={mdiTwitter} title="Twitter" size={1} horizontal vertical rotate={180} color="#1da1f2" /></Link>
        <Link to="#" className="instagram"><Icon path={mdiInstagram} title="Instagram" size={1} horizontal vertical rotate={180} color="#1da1f2" /></Link>
        <Link to="#" className="linkedin"><Icon path={mdiLinkedin} title="Linkedin" size={1} horizontal vertical rotate={180} color="#1da1f2" /></Link>
        </div>
        </div>            

        <div style={{borderRadius:"20px", backgroundColor:"#f7f9f9", border: "1px dashed #ccc", margin: "25px auto 10px", padding: "10px 20px"}}>
          <h6>Download our Apps</h6>
          <div style={{display:"flex", justifyContent:"space-between"}}>
<a target="_blank" href="https://itunes.apple.com/us/app/myjoyonline/id1166191375?mt=8" style={{margin:"5px 0px", padding:"0px 12px", color:"#333333", fontSize: "25px"}}>
<span><i className="fa-brands fa-apple"></i></span>
{/*<img className="store" style={{width:"133px", height:"39px"}} src="https://www.myjoyonline.com/wp-content/uploads/2019/11/appstore.png" />*/}
</a>

<a target="_blank" href="https://play.google.com/store/apps/details?id=com.multimedia.joyonline" style={{margin:"5px 0px", padding:"0px 12px", color:"#333333", fontSize: "25px"}}>
<span><i className="fa-brands fa-google-play"></i></span>


{/*<img className="store" style={{width:"133px", height:"39px"}} src="https://www.myjoyonline.com/wp-content/uploads/2019/11/playstore.png" />*/}
</a>
<a target="_blank" href="https://appgallery7.huawei.com/#/app/C102594569" style={{margin:"5px 0px", padding:"0px 5px", color:"#333333", fontSize: "25px"}}>
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="32" height="32"
viewBox="0 0 36 20"
style={{fill:"#333333"}}>    <path d="M 13 4 C 10.97 4 9.4052969 5.7677656 9.4042969 9.0097656 C 9.4042969 11.126766 12.608219 16.5575 15.449219 21.3125 C 15.449219 9.0005 15.146 7.667 13 4 z M 19 4 C 16.812 7.604 16.550781 8.9995 16.550781 21.3125 C 19.391781 16.5575 22.595703 11.126766 22.595703 9.0097656 C 22.594703 5.7667656 21.03 4 19 4 z M 6 8 C 3.021 10.079 4.0009062 15.000422 5.5039062 16.607422 C 6.4969063 17.647422 10.35 19.52 14 22 L 6 8 z M 26 8 L 18 22 C 21.65 19.52 25.503094 17.647422 26.496094 16.607422 C 27.999094 15.000422 28.979 10.079 26 8 z M 2 16 C 2.048 21.542 5.4307969 23 7.7167969 23 L 13.431641 23 L 2 16 z M 30 16 L 18.568359 23 L 24.283203 23 C 26.569203 23 29.952 21.542 30 16 z M 5.1171875 24 C 5.4361875 25.654 6.1573281 27 8.2363281 27 C 10.315328 27 12.325641 25.8 13.431641 24 L 5.1171875 24 z M 18.568359 24 C 19.674359 25.8 21.684672 27 23.763672 27 C 25.842672 27 26.563813 25.654 26.882812 24 L 18.568359 24 z"></path></svg>
{/*<img className="store" style={{width:"148px", left: "-8px", position: "relative"}} src="https://www.myjoyonline.com/wp-content/uploads/2020/07/huawei-app-gallery.png" />*/}
</a>
</div>
</div>
 
 <div id="copyrightl" ref={sidebarRef}>
<div>
  <div><a href="https://www.multimediaghana.com/">The Multimedia Group</a> | <a href="https://www.multitvworld.com/">MultiTVWorld</a></div>
  <a href="">Advertise With Us</a> | <a href="">Contact Us</a>
</div>
<div><Link to={{pathname:"/terms"}}>Terms</Link> | <Link to={{pathname:"/privacy"}}>Privacy</Link><br /> Copyright  &copy; {new Date().getFullYear() }&nbsp;MyjoyOnline</div>
        </div>
        </div>
            </div>
        </div>
    
    );
    }
    
export default SideBar;