import React, { useEffect, useRef, useState, memo, useMemo } from "react";
import { useSwipeable, Swipeable } from 'react-swipeable';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
import usePlayList from './videolist.js';
import usePlaylistItem from './useGetPlayListItems.js';
import ReactPlayer from 'react-player';



const imgstyle={

    borderRadius: "20px",
    width:"100%",
}


//get playlist videos#

//$api_url = 'https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=25&playlistId=PLEhaP2Z2iO3rdSWK2khGX1ilOoM6wphw0&key=AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U';

//Channel playlist
//https://youtube.googleapis.com/youtube/v3/playlists?part=snippet,contentDetails&channelId=UChd1DEecCRlxaa0-hvPACCw&key=AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U



function VideoReel(props) {

  const  { playlist, error, loading } = usePlaylistItem("UChd1DEecCRlxaa0-hvPACCw", 5, "AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U", props.playlistid)
  const [touchPosition, setTouchPosition] = useState(null)
  const [pos, setPos] = useState(1)

 

  const prevHandler=(e)=>{
    e.preventDefault() 
    if(playlist.length > 0){
      if(pos > 0){ 
        setPos(prevPos => prevPos - 1)
        }
    }   
  }
  
  const nextHandler=(e)=>{
    e.preventDefault()
    if(playlist.length > 0){
    if((pos < playlist.length/3)){ 
    setPos(prevPos => prevPos + 1)
  }
  }
  }
  
    const handleTouchStart = (e) =>{
  
    const touchDown = e.touches[0].clientX
    setTouchPosition (touchDown)
    //console.log(touchPosition)
  
   }
  
   const handleTouchMove = (e)=>{
    const touchDown = touchPosition
    if(touchDown === null){
      return
    }
  
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
  
    if(diff > 5){
  
      nextHandler()
    }
  
    if(diff < -5){
  
      prevHandler()
    }
    
    setTouchPosition(null)
  
   }

   
  
  useEffect(()=>{

   //console.log(playlist)
  
  },[props.playlistid])

  if(error){

    return(

      <div>{error}</div>
      
    )
  }

  if(loading){

    return(

        <div>{loading}</div>
    )
}

  if(playlist.length > 0){
   
  return (
   
    <div style={{backgroundColor:"#ffffff",maxWidth:"600px", padding:"20px 20px 20px 15px", margin:"20px auto", overflow:"hidden", borderRadius:"10px", position:"relative"}} className="sd" >
    <div style={{position:"absolute", top:"50%", width:"100%", color:"#ffffff",zIndex:"50"}} className="slidecontrolls"><Link to="#" className="prev" onClick={prevHandler}><i class="fa-solid fa-angle-left"></i></Link><Link to="#" className="next" onClick={nextHandler}><i class="fa-solid fa-angle-right"></i></Link></div>
    <div><h6>{props.playlisttitle}</h6></div>
    <div style={{width:(390 + (playlist.length*230)) +"px", position:"relative", transition: "all 0.2s", transform: `translateX(${pos * -430}px)`}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
    {playlist.map((item,index)=>{

return(

<div className="videocontainer">
<Link to="#" key={index+item.id}>
 {/*<ReactPlayer url={"https://www.youtube.com/watch?v="+item.snippet.resourceId.videoId} controls width="100%" light={"https://i4.ytimg.com/vi/"+item.snippet.resourceId.videoId+"/hqdefault.jpg"}/>*/}
 <div className="videotitle"  onClick={event => props.handlePlay("https://www.youtube.com/watch?v="+item.snippet.resourceId.videoId)} style={{backgroundImage: "url(https://i4.ytimg.com/vi/"+item.snippet.resourceId.videoId+"/hqdefault.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div></Link>
 </div>
 )

 })

    }
   
         
     </div>
  

     </div>
   
  );
}
}

export default VideoReel;