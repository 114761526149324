import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import { useEffect } from "react";

//import "./styles.css";




export default function GallerySwiper(props) {


  const imgstyle={

    borderRadius: "20px",
    width:"100%"
}

//const items = props.photoitems.split(",")
  const [gg,setGg] = useState("")

  useEffect(()=>{
   // if(ismount){

      //console.log(String(props.photoitems))
      //setGg(String(props.photoitems))

   // }
    

  },[props.photoitems])

  //let ismount = true
  
  //console.log(props.photoitems)
  //var photoitems = props.photoitems.split(",")
 
 /* if(gg !== ""){


    var itemsarray = gg.split(",");

    console.log(itemsarray)
  }

    itemsarray.length > 0 &&
    itemsarray.map((item,index) => {

        return(
        <div></div>
        )
      })*/
   
  
  return (
    <>
      <Swiper keyboard={true} mousewheel={true} pagination={{dynamicBullets: true}} navigation={true} modules={[Navigation, Pagination, Mousewheel, Keyboard]} className="mySwiper">
       {/*
        items.length > 0 &&

        items.map((item,index) => {

          <SwiperSlide><img src={item} /></SwiperSlide>

        })
      */}
      <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297397017_901129437408194_7386703541417005248_n.jpg" alt="bella" style={imgstyle} /></SwiperSlide>
        <SwiperSlide> <img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297102752_429220069267141_2036302322334821818_n.jpg" alt="bello" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297460818_126606516750401_5084954078496452603_n.jpg" alt="belli" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297440597_766182018048445_1196010029965794185_n.jpg" alt="belln" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297458943_1257027478376589_190405792501522396_n.jpg" alt="belle" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297220734_7823227184385788_6350405349552040358_n.jpg" alt="bellt" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297146913_3234148576861397_2565825005409523707_n.jpg" alt="bellw" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297675279_1754853031525100_8657260273630582201_n.jpg" alt="bellc" style={imgstyle} /></SwiperSlide>
        <SwiperSlide><img src="https://www.bellanaija.com/wp-content/uploads/2022/08/297146913_3234148576861397_2565825005409523707_n.jpg" alt="belld" style={imgstyle} /></SwiperSlide>
        
      </Swiper>
    </>
  );
}