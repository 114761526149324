import React,{useState, useEffect} from 'react';
import { ApolloClient, InMemoryCache, useQuery, gql, from, HttpLink } from '@apollo/client';
import { LOAD_RELATED } from '../graphql/queries.js';


export default function useRelatedStoriesList(itemtag){
    let ismount = true
    const [newsitems,setNewsitems] = useState({});
   

    const{error, loading, data} = useQuery( LOAD_RELATED, {variables: {tag: itemtag}} );

   

    useEffect(() => {
if(ismount){      

        if(data){
           
            //strg(storyitem[0].node.tags)
            setNewsitems(data.posts.nodes) 
        //console.log(storyitem)
    
         }

}

        

    },[data]);

    
return { newsitems, error, loading  }
}