import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { PHOTO_GALLERY } from "../graphql/queries";


export default function useGalleryList(postid){

let ismount = true;

    const {data, error, loading} = useQuery(PHOTO_GALLERY, {variables:{postIdString:postid}})
    const [photoitems,setPhotoitems] = useState({})
   

    useEffect(() => {
if(ismount){      

        if(data){
           
            //strg(storyitem[0].node.tags)
            setPhotoitems(data.photoGallery) 
        console.log(data)
    
         }

}
    },[data]);

    
return { photoitems, error, loading  }
}