import {gql} from '@apollo/client';


export const LOAD_STORY = gql`
query getstory($slug: String!) {
    postBy(slug: $slug) {
      content
      source
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
        }
      }
      title
      id
      excerpt
      dateGmt
    }
  }
`

export const SEARCH_DATA =gql`
query searchData($search: String!) {
  posts(where: {search: $search, orderby: {field: DATE, order: DESC}}) {
    nodes {
      featuredImage {
        node {
          sourceUrl
        }
      }
      id
      title
      dateGmt
      excerpt 
      slug
      tags {
        nodes {
          name
        }
      }
    }
  }
}
`
export const TAGS_QUERY = gql`
query tag_query($tag: String, $last: Int, $first: Int = 10, $before: String = "", $after: String = "") {
  posts(
    after: $after
    before: $before
    first: $first
    last: $last
    where: {tag: $tag,  orderby: {field: DATE, order: DESC}}
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      cursor
   
    node {
      dateGmt
      categories {
        nodes {
          name
          parent {
            node {
              name
            }
          }
        }
      }
      excerpt
      id
      link
      slug
      tags {
        nodes {
          name
        }
      }
      title
    }
  }
     }
}`


export const TRENDS = gql`

query tagssq{
  tags(where: {orderby: COUNT}) {
    nodes {
      posts {
        nodes {
          title
        }
      }
    }
  }
}
`


export const LOAD_LATEST_STORIES = gql`
query posts($categoryName: String, $last: Int, $first: Int, $before: String, $after: String) {
  posts(
    where: {status: PUBLISH, categoryName: $categoryName, orderby: {field: DATE, order: DESC}}
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    nodes {
      id
      link
      excerpt
      dateGmt
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
          slug
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      slug
      title
      categories {
        nodes {
          name
          parent {
            node {
              name
            }
          }
        }
      }
    }
  }
}
`



export const LOAD_NEWSFEED = gql`
query NewQuery($last: Int, $first: Int, $before: String, $after: String, $categoryName: String) {
  posts(
    after: $after
    before: $before
    last: $last
    first: $first
    where: {categoryName: $categoryName, orderby: {field: DATE, order: DESC}}
  ) {
    edges {
      cursor
      node {
        dateGmt
        excerpt
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        id
        slug
        source
        status
        tags {
          nodes {
            name
          }
        }
        title
        link
        categories {
          nodes {
            name
            parent {
              node {
                name
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}`



export const LOAD_ALL = gql`
query posts($categoryName: String, $after: String, $before: String, $first: Int, $last: Int) {
  posts(
    where: {status: PUBLISH, categoryName: $categoryName, orderby: {field: DATE, order: DESC}}
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    nodes {
      id
      link
      excerpt
      dateGmt
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
          slug
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      slug
      title
      categories {
        nodes {
          name
          parent {
            node {
              name
            }
          }
        }
      }
    }
  }
}`


export const LOAD_RELATED = gql`
query LoadRelatedStories($tag: String) {
  posts(where: {tag: $tag}) {
    nodes {
      id
      title
      dateGmt
      excerpt
      content
      source
      featuredImage {
        node {
          sourceUrl
        }
      }
      tags {
        nodes {
          name
        }
      }
      slug
      categories {
        nodes {
          name
          parent {
            node {
              name
            }
          }
        }
      }
    }
  }
}
`

export const LOAD_RELATED_STORIES = gql`
query LOAD_RELATED_STORIES($tag: String) {
    posts(tag: $tag){
        edges {
          node {
            excerpt
            featuredImage {
              node {
                sourceUrl
              }
            }
            tags {
              nodes {
                name
              }
            }
            content
            dateGmt
            date
            link
            slug
            title
            uri
            id
          }
        }
      }
  }`

export const PHOTO_GALLERY = gql`query PHOTO_GALLERY($postIdString: String ) {
  photoGallery(postIdString: $postIdString) {
    count
    fullGallery
    items
    title
  }
}`