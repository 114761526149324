import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
    <ContentLoader 
      speed={2}
      width={550}
      height={450}
      viewBox="0 0 400 350"
      backgroundColor="#eeeeee"
      foregroundColor="#cccccc"
      {...props}
    >
      <rect x="0" y="8" rx="3" ry="3" width="100%" height="6" /> 
      <rect x="0" y="20" rx="3" ry="3" width="10%" height="6" />
      <rect x="50" y="20" rx="3" ry="3" width="10%" height="6" />
      <rect x="100" y="20" rx="3" ry="3" width="10%" height="6" />
      <rect x="0" y="33" rx="3" ry="3" width="100%" height="300" />
      <rect x="0" y="340" rx="3" ry="3" width="100%" height="6" />      
    </ContentLoader>
  )
  
  export default MyLoader;