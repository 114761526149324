import {useState, useEffect} from 'react';
import axios from "axios";

export default function useVideosList(channelId, maxresults, apikey){
    let ismount = true
    const [videoitems, setVideoitems] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

   

    if(apikey == null || channelId == null){
        //console.log("error")
    }
    useEffect(() => {

        if(videoitems.length !== 0){

           // console.log(videoitems)
        }
       
        setLoading(true)
        setError(false)
        let cancel
        
        axios({
            method: 'GET',
           // url: 'https://www.googleapis.com/youtube/v3/search',
            //url: 'https://www.myjoyonline.com/newsfeed-video-list-copy.json',
            url: 'https://www.myjoyonline.com/wp-json/mjo/v1/newsfeedvideolist',
            /*params:{
            order:"date",
            channelId:channelId,
            maxResults:maxresults,
            key:apikey,
            part : "snippet",
            
            },*/

            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res =>{
            if(ismount){
           // JSON.encode(setVideoitems(prevVideoitems => {return prevVideoitems, res.data.items}));
            setVideoitems(prevVideoitems => {return prevVideoitems, res.data.items});
            setLoading(false)
            }
        }).catch(e => {
            if(axios.isCancel(e)){

                console.log("Error loading data from youtube api")
            } 
            
            console.log(e)
            setError(true) 
            setLoading(false)
            console.log("error loading youtube api")
        })

    },[apikey, channelId, maxresults]);
    
return { videoitems, error, loading }
}