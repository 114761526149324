import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Outbrain from './outbrain';
//import Trinity from './trinity';
import CommentComponent from './commentcomponent';
//import Customad from './customad';
//import GoogleAds from './googleads';
import InArticlead from './inarticlead';
import { OutbrainWidget, showOutbrainWidget } from 'react-outbrain-widget';
import ShareButtons from './socialshare';


const StoryItem = React.forwardRef((props, ref) =>{
  
    const random_id = Math.random(0,1000000)
    const [listvalue,setListValue] = useState([]);
    let baseurl = window.location.origin;
    //const [adStory, setAdStory] = useState("<h1>Empty</h1>");
    const Listify = () => {
        if(props.tags)
        setListValue(props.tags.split(","));
    }
 
   useEffect(()=>{
    Listify();
  },[props.tags]);

  const interactiveStyle = { 
    padding: "10px auto",
    marginBottom: "10px"
  }

  const interactiveStylebottom = { 
   background: "#ffffff",
   border: "8px solid #f7f9f9",
   position: "absolute",
   bottom: "-30px",
   borderRadius: "50px",
   width: "92.5%",
   paddingTop: "0px",
 }

const vkscript = () =>{


  /*//var vconfig = document.createElement("script")
  var vkkconfig = document.getElementById("vkkconfig")
  var vconfig = document.createTextNode("var VUUKLE_CONFIG = { host: 'myjoyonline.com', apiKey: '255be353-1ccf-4eea-af0c-86d618375550', language: 'en', articleId:"+ random_id+", };")
  vkkconfig.appendChild(vconfig)*/
  window.VUUKLE_CONFIG.articleId = random_id

  var vsc = document.getElementById('vkkkscript')
  var vs = document.createElement("script")
  var vsnode = document.createTextNode("window.newVuukleWidgets({elementsIndex: '"+random_id+"',articleId: '"+random_id+"',img: '"+props.storyimage+"',tags: '"+props.tags+"',url: '"+props.storyurl+"'});")
  //vs.appendChild(vsnode)
  //vsc.appendChild(vs)
}



//console.log(props.title)
useEffect(()=>{

  vkscript();
  attachevuukle();

},[props.storyid]);


const attachevuukle = () =>{

  var d = document,
  s = d.createElement('script');
  s.async = true;
  s.src = 'https://cdn.vuukle.com/platform.js';
  (d.head || d.body).appendChild(s);

}



//date ago function
const dpub = (pdate) =>{
  var dp = new Date(pdate)
 
  return dp.toDateString() + " " + dp.toLocaleTimeString();
}


let st = "";
//let nc = 0;
 const placead = () =>{
  var storycontent = ""
  var n = 0;
  st = props.content.split("</p>");
  for(let g=0; g < st.length; g++){

    if((g % 4 === 0 && g !== 0) && n < 2){
      //let adcon = Math.floor(Math.random()*200000000);

      //storycontent += "<div id='div-gpt-ad-1651994468322-"+adcon+"'><h1>add here</h1></div>";
      
     // dyad("/1002554/Myjoyonline/myjoyonline_in_article_mid", adcon);

      storycontent += st[g];
      n++;

    }else{

      storycontent += st[g];
    }

  }
  
  return storycontent
 }
//console.log(props.storyurl)

var cc = props.content ? placead() : null

return(

    
    <div id="storytop" style={{ position: "relative",zIndex:"0", backgroundColor: "#ffffff", borderRadius:"20px", padding:"20px", marginBottom:"40px", marginTop:"40px" }}>
<article>


   
   <div className="article-title">
   <h1 style={{fontSize: "22px", marginBottom:"0px"}}>{props.title}</h1>
   </div>
   <div style={{color:"rgb(151 146 146)", fontSize:"12px", margin:"10px 0px", display:"flex", justifyContent:"space-between"}}><span>Source: {props.source}</span><span>{dpub(props.datepublished)}</span></div>
   { 
   <div className="interactiveicons" style={interactiveStyle}>
   <ShareButtons title={props.title} url={baseurl+"/"+props.storyurl.slice(0,props.storyurl.length-1)} twitterHandle={"_MsLinda"} tags={listvalue}/>
   </div>
}
   
   <img src={props.storyimage} style={{width:"100%", marginBottom:"10px"}} alt={props.title} />
  
   { 
   <InArticlead id="myjoyonline_in_article_wrap" slot="/1002554/Myjoyonline/myjoyonline_in_article_wrap"  style={"left"} />

}
   {
    st.length > 0 &&
    st.map((item, index) => {

      if(index % 4 === 0 && index > 0){

        return(
          <>
          <div id="article-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>
   
          </div>
          <InArticlead id={"myjoyonline_in_article_midad"+index} slot="/1002554/Myjoyonline/myjoyonline_in_article_mid" />
          </>
        )

      }else{

        return(

          <div id="article-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>
   
          </div>
        )
      }
    })
   }

<div className="tagLabel">{
                   listvalue.map((lst,index) =>( <Link key={index} to={{pathname:"/tag/"+lst.replace("\s","-"), state:{tag:lst}}}>{lst}</Link>))

                   
  }</div>


<div>{/*<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.summary)}}></div>*/}</div>


{ 
<>
  {/*<CommentComponent apikey="255be353-1ccf-4eea-af0c-86d618375550" tags={props.tags} author="selorm" articleid={random_id} />*/}

{/*<div className="interactiveicons" style={interactiveStylebottom}>
   <Link to="#"><i className="far fa-comment"></i><span style={{padding:"1px 5px", fontSize:"12px",borderRadius:"200px", position:"absolute", color:"#000000", left:"23px", bottom:"2px"}}>8</span></Link> 
   <Link to="#" className="fb"><i className="fa fa-facebook" aria-hidden="true"></i><span style={{padding:"1px 5px", fontSize:"12px",borderRadius:"200px", position:"absolute", color:"#000000", left:"23px", bottom:"2px"}}>245</span></Link> 
   <Link to="#" className="twitter"><i className="fa fa-twitter" aria-hidden="true"></i><span style={{padding:"1px 5px", fontSize:"12px",borderRadius:"200px", position:"absolute", color:"#000000", left:"23px", bottom:"2px"}}>105</span></Link>
   <Link to="#" className="whatsapp"><i className="fa fa-whatsapp" aria-hidden="true"></i><span style={{padding:"1px 5px", fontSize:"12px",borderRadius:"200px", position:"absolute", color:"#000000", left:"23px", bottom:"2px"}}>10</span></Link>
   <Link to="#"><i className="fa fa-envelope-o" aria-hidden="true"></i><span style={{padding:"1px 5px", fontSize:"12px",borderRadius:"200px", position:"absolute", color:"#000000", left:"23px", bottom:"2px"}}>15</span></Link>

   </div>*/}
   </>
}

<div id='vuukle-comments'></div>
<div class='vuukle-powerbar'></div>
<div id={"vkkkscript"+random_id}></div>

   {

    <OutbrainWidget dataSrc={"myjoyonline.com"} dataWidgetId="GS_1" />
    /*<Outbrain storyurl={props.storyurl} slug={props.slug} widgetid={"GS_1"} />*/
   }
   </article>
</div>

)

})

export default StoryItem;