import React,{useState, useEffect} from 'react';
import { ApolloClient, InMemoryCache, useQuery, gql, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LOAD_ALL, LOAD_LATEST_STORIES, LOAD_NEWSFEED } from '../graphql/queries.js';
import axios from "axios";

export default function useItemsList(categories){
    let ismount = true
    const [newsitems,setNewsitems] = useState({});
    //const [error, setError] = useState(false);
    //const [loading, setLoading] = useState(true);
    const [items,setItems] = useState({})

    const updateQuery = ({previousResult}, { fetchMoreResult }) => {
        //fetchMoreResult.posts.edges = [...previousResult.posts.edges, ...fetchMoreResult.posts.edges]
        return fetchMoreResult;
      };
    
    const {error, loading, data, fetchMore} = useQuery(LOAD_NEWSFEED, {categories});
   // console.log(loading)
    if(categories == null){
        //console.log("error")
    }


    useEffect(()=>{

        if(data){

            //console.log(data.posts.edges)
            setNewsitems(data.posts.pageInfo);
            setItems(data.posts.edges)
            //console.log(data.posts.edges)
            //setLoading(false)
            }
    },[data])

    /*useEffect(() => {
        if(data){

            //console.log(data)
        }
        setLoading(true)
        setError(false)
        let cancel

        axios({
            method: 'GET',
            url: 'https://www.myjoyonline.com/wp-json/mjo/v1/lateststories/',
            params:{
            page:page,
            order_by:"date",
            sort:"desc",
            status:"publish",
            category: categories},

            cancelToken: new axios.CancelToken(c => cancel = c)
        }).then(res =>{
            if(ismount){
            setNewsitems(prevNewsitems => {return prevNewsitems, res.data});
            setLoading(false)
            }
        }).catch(e => {
            if(axios.isCancel(e)){

                console.log("Error loading data from api")
            } 
            
            console.log(e)
            setError(true) 
            setLoading(false)
            console.log("error loading api")
        })

    },[ page, categories ]);*/

    
return { newsitems, error, loading, items, fetchMore, updateQuery }
}