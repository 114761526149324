import { commentSmile } from 'fontawesome';
import React, { useEffect, useState, memo } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import usePlayList from './useplaylist';
import Video from './video'
import useVideosList from './videolist.js';
import VideoReel from './videoreel';


function Videos(){

        const [playurl, setPlayurl] = useState("");
        const [videolist,setVideolist] = useState()
        const [position, setPosition] = useState(0);
        const [videostyle, setVideostyle] = useState({display:"none"})
        let  apikey = "AIzaSyASbmvjw5uwy1dKTjYCP6rf7MLf2FCNpag";
        let channelId = "UChd1DEecCRlxaa0-hvPACCw";
       //const { videoitems, error, loading } = usePlayList(channelId, 20, apikey);
       //let vindex = -1;
       const [p_i_p, setP_i_p] = useState(true);

       const handlePlay = url =>{

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        
       setPlayurl(url)
       setVideostyle({display:"block"});
        
       }

       
       const handlePictureInPicture = () => {
       
        if(position >= 400){
            setP_i_p(true);  
        }else{

            setP_i_p(false);
        }
       
        setPosition(window.pageYOffset)
        
      };

      useEffect(()=>{
window.addEventListener("scroll", handlePictureInPicture);

      return () => {
        window.removeEventListener("scroll", handlePictureInPicture);
      };
       
      },[position])
    

        //const  { videoitems, error, loading } = useVideosList("UChd1DEecCRlxaa0-hvPACCw", 400, "AIzaSyB8n28NznvwpjKVm57ovejv_0X5GjqAu0U")

        let videoitems=[{
            "title":"AM Showbiz",
        "id":"PLEhaP2Z2iO3p7fKPG3HhrC19f_qPDQsVi",
        "time":"time of day"},
          {       
            "title":"AM Show",
            "id":"PLEhaP2Z2iO3qhUCo8GGOQ1LYragQnl9im",
            "time":"time of day"
        },
        {
        
            "title":"AM Show Newspaper Headlines",
            "id":"PLEhaP2Z2iO3oqHIvPNByf1HI6MnXBCvMr",
            "time":"time of day"
        },
        {
            "title":"AM News",
            "id":"PLEhaP2Z2iO3o9Il9c8uq72pWOrNlkv8bl",
            "time":"time"
        },
        {
            "title":"AM Talk",
            "id":"PLEhaP2Z2iO3qomGcf_L1_B0fqLyzWZoox",
            "time":"time"
        },
        {
            "title":"News Desk",
            "id":"PLEhaP2Z2iO3rWptitHP3j0O96ykLayXFj",
            "time":"time"
        },
        {
            "title":"Joynews Today",
            "id":"PLEhaP2Z2iO3rVwwL_oiZQNu0UqiE4-Ov7",
            "time":"time"
        },
        {
            "title":"Midday News",
            "id":"PLEhaP2Z2iO3qa6nc5PKNnmUbfeWIQPUHP",
            "time":"time"
        },
        {
            "title":"The Pulse",
            "id":"PLEhaP2Z2iO3o5JwNr9hP5CgcCF0foiFeC",
            "time":"time"
        },
        {
            "title":"Upfront",
            "id":"PLEhaP2Z2iO3oeWI47gZzjRJmk-4oKCZEa",
            "time":"time"
        },
        {
            "title":"Hotline Documentary",
            "id":"PLEhaP2Z2iO3onxQi1uKTIB1otKPskTHAP",
            "time":"time"
        },
        {
        "title":"Am Sports",
        "id":"PLEhaP2Z2iO3rWCfuRcgvTGITlFMsV_rBD",
        "time":"time"
        },
        {
        "title":"Sports Desk",
        "id":"PLEhaP2Z2iO3remR8mMDD7kC-TMTKUML88",
        "time":"time"
        },
        {
        "title":"Joy Sports Today",
        "id":"PLEhaP2Z2iO3qugfj59_mtf0Oh0-AK3Whl",
        "time":"time"
        },
        {
        "title":"Joy Sports",
        "id":"PLEhaP2Z2iO3rJocXuhAGDV56pHzm0a0E7",
        "time":"time"
        },
     /* {
            "title":"Sports Review",
            "id":"PLEhaP2Z2iO3ptaSfikyEBfiOpKBbY1lE6",
            "time":"time"
            },*/
        {
        "title":"AM Business",
        "id":"PLEhaP2Z2iO3p7fKPG3HhrC19f_qPDQsVi",
        "time":"time"
        },]


   /* if(error){

        return(

            <div>{error}</div>
        )
    }

    if(loading){

        return(

            <div>{loading}</div>
        )
    }*/
   
    if(videoitems){
    return(

        <>
        
        <div style={{maxWidth: "556px", width: "556px", zIndex:"200", top:"40px", paddingTop:"10px", background:"#ffffff"}}>
        <ReactPlayer url={playurl} width="100%" playing={true} pip={true} style={videostyle}/>
        </div>

        { videoitems.map((item,index)=>{


               return ( 
                <div style={{position:"relative", zIndex:"1"}}>
                <VideoReel key={index} playlistid={item.id} playlisttitle={item.title} handlePlay={handlePlay}/>
                </div>
               )
        })
            
            
            }
        
        </>
    )
    }
}

export default Videos;