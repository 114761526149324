import { Link } from 'react-router-dom';
import logo from '../joylogo.png';
/*import { MdDns } from "react-icons/md";*/
import Icon from '@mdi/react';
import {mdiAccessPoint} from '@mdi/js';
import {mdiTelevisionClassic } from '@mdi/js';
import {mdiPodcast } from '@mdi/js';
import {mdiPlayCircleOutline } from '@mdi/js';

function TopBar(){

    const styles = {

        borderRadius:"20px",
        backgroundColor:"rgb(191 36 36)",
        color: "#ffffff",
        border:"none",
        padding:"3px 10px"

    }

return(
 
<div className="container zero-padding" style={{float:"left",position: "fixed", zIndex:"5"}}>
    
    <div className="top-nav">
        
    <Link to="/" className="show-mobile"><img src={logo} width="50px" alt="Site Logo "/></Link>
    <Link to={{pathname:"/livetv", state:{vdurl:"https://www.dailymotion.com/embed/video/x7w7197"}}}><Icon path={mdiTelevisionClassic} size={1} horizontal vertical rotate={180} color="#fff" /><span className="hide-mobile sp-pos">Tv</span></Link>
    <Link to={{pathname:"/radio", state:{stationurl:"https://sgplayer.streamguys1.com/atunwa/myjoyonline/mgl/joyfm/embed.min.js?v=3.1.0c", name:"JoyFm"}}}><Icon path={mdiAccessPoint} size={1} horizontal vertical rotate={180} color="#fff" />
    <span className="hide-mobile sp-pos">Radio</span></Link>
    <Link to={{pathname:"/podcast"}}><Icon path={mdiPodcast} size={1} horizontal vertical rotate={180} color="#fff" /><span className="hide-mobile sp-pos">Podcast</span></Link>
    <Link to={{pathname:"/videos"}}><Icon path={mdiPlayCircleOutline} size={1} horizontal vertical rotate={180} color="#fff" /><span className="hide-mobile sp-pos">Videos</span></Link>
    
    </div>
    </div> 
    
);
}

export default TopBar;