import React,{ useEffect } from 'react';
import Ads from './ads';




function GoogleAds(props){  

    
        return (

            
                    <Ads id={props.id} slot={props.slot} />
               
        );

    }
export default GoogleAds;