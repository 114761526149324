import { useEffect, useState } from "react";
import GoogleAds from "./googleads";



function InArticlead(props){

  const [adcon, setAdcon] = useState(Math.floor(Math.random()*200000000));
  const dyad = () =>{
   
    var sddd = document.createElement("script")
    var ud = document.getElementById(props.id)
      var txtnode = document.createTextNode("googletag.cmd.push(function() {"+
        "var scrollslot = googletag.defineSlot('"+props.slot+"', [[300, 250]])"+
          ".setTargeting('page', ['article']).setTargeting('refresh', 'true').setTargeting('url', ['myjoyonline.com'])"+
          ".addService(googletag.pubads());"+

        "var div = document.createElement('div');"+
        "div.id = scrollslot.getSlotElementId(); document.getElementById('"+props.id+"').appendChild(div);"+

       "googletag.display(scrollslot);}); ")
       sddd.appendChild(txtnode)
       document.body.appendChild(sddd)

  }

  
  useEffect(()=>{
    
      dyad()

  },[])
  
return( 
    
    
    <div>
      
      {
        <div className="_feeditem" id={props.id} style={{background: "transparent !important", padding: "0px 15px 15px 0px", border: "none", float: props.style,}}>
         
        </div>
        }
        </div>
    );
}

export default InArticlead

