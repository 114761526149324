
import RelatedStories from './relatedstories.js';
import {useLocation} from 'react-router-dom';


export default function Tags(){
    const location = useLocation()
    const tg = location.state?.tag
    /*const { newsitem, error, loading } = useRelatedStoriesList("stone bwoy")*/
    //console.log(tg)
    return(
        <>
        <div style={{padding:"10px 0px 15px 0px", fontWeight:"600", fontSize:"22px"}}><span style={{color:"#333333", fontStyle:"italic", fontWeight:"100"}}>Showing stories related to</span> {tg}</div>
        <RelatedStories tagged={true} tags={tg.replace(/ /gi,"-")} />
        </>
 
  )

}