
import React from "react";

export default function CurrencyRow({
    currency,
    onChangeAmount,
    amount
}) {
    
  
  return (
      <div className="fx_row">  
          <span className="fx_flags"><img src={currency.flag} /></span>
          <span className="fx_title">{currency.abrev} - {currency.title}</span>
          <span className="fx_input_wrapper">
               <span className="fx_symbol">{currency.symbol}</span>
                <input
                  type="number"
                  className="fx_input"
                  value={amount}
                  min="0.01"
                  max="10000000"
                  step="0.01"
                  onChange={onChangeAmount}
              />
          </span>
    </div>
  )
}